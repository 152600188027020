export default (props, vars) => {
  const applicationOffer = vars?.['useapplicationoffer2']?.['applicationOffer'];
  if (!applicationOffer) return '';

  const { start_date, end_date } = applicationOffer;

  const displayDate = (dateStr) =>
    dateStr
      ? ` (${vars?.['useapplicationoffer2']?.[
          'applicationOffer'
        ].formatDate2Details(new Date(dateStr))})`
      : '';

  return `If you have any additional questions or need to adjust your start date${displayDate(
    start_date
  )}, and end date${displayDate(end_date)}, please respond to the offer email.`;
};
