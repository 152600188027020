/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import PoweredByParagonOne from '../PoweredByParagonOne';
import conditionFn from './code/if/conditionFn.js';
import Image from '@clutch-marketplace/web-elements/dist/cjs/components/Image';
import conditionFn1 from './code/if/conditionFn-1.js';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import textFn1 from './code/text/textFn-1.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import textFn2 from './code/text/textFn-2.js';
import textFn3 from './code/text/textFn-3.js';
import Button from '@clutch-marketplace/web-elements/dist/cjs/components/Button';
import conditionFn3 from './code/if/conditionFn-3.js';
import Icon from '../Icon';
import onClickFn from './code/button/onClickFn.js';
import styles from './styles.module.scss';

const OfferLetterHero = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '69_24',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_69_51, props.className])}
      ref={ref}
      key={getKey(report, '69_51')}
    >
      <PoweredByParagonOne
        key={getKey(report, '69_50')}
        data-d={getKey(report, '69_50')}
      />
      <Box
        tag="div"
        className={styles.clt_70_148}
        key={getKey(report, '70_148')}
      >
        {getKey(report, '69_52', null, '__CLUTCH_IF__') &&
        b.tryCatch(conditionFn, props, vars) ? (
          <Image
            src={props?.applicationOffer?.company_logo}
            className={styles.clt_v200_103153}
            key={getKey(report, 'v200_103153')}
          />
        ) : null}
        <Box
          tag="div"
          className={styles.clt_69_78}
          key={getKey(report, '69_78')}
        >
          {getKey(report, '81_48', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn1, props, vars) ? (
            <Element key={getKey(report, 'v200_103231')}>
              <Text
                text={b.tryCatch(textFn, props, vars)}
                tag="h1"
                key={getKey(report, '69_10')}
              />
              <Text
                text={b.tryCatch(textFn1, props, vars)}
                tag="p"
                key={getKey(report, '69_55')}
              />
            </Element>
          ) : null}
          {getKey(report, '81_105', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn2, props, vars) ? (
            <Element key={getKey(report, 'v200_103249')}>
              <Text
                text={b.tryCatch(textFn2, props, vars)}
                tag="h1"
                key={getKey(report, '81_106')}
              />
              <Text
                text={b.tryCatch(textFn3, props, vars)}
                tag="p"
                key={getKey(report, '81_107')}
              />
              <Button
                type="button"
                disabled={false}
                onClick={props?.onViewOfferDetails}
                className={styles.clt_199_46}
                key={getKey(report, '199_46')}
              >
                <Text
                  text="View Offer Details"
                  tag="span"
                  key={getKey(report, '199_47')}
                />
              </Button>
            </Element>
          ) : null}
        </Box>
        {getKey(report, '76_8', null, '__CLUTCH_IF__') &&
        b.tryCatch(conditionFn3, props, vars) ? (
          <Button
            onClick={b.tryCatch(onClickFn, props, vars)}
            type="button"
            disabled={false}
            className={styles.clt_v200_103217}
            key={getKey(report, 'v200_103217')}
          >
            <Icon
              name="see_below"
              className={styles.clt_70_142}
              key={getKey(report, '70_142')}
              data-d={getKey(report, '70_142')}
            />
          </Button>
        ) : null}
      </Box>
    </Box>
  );
});

OfferLetterHero.displayName = 'OfferLetterHero';

export default OfferLetterHero;
