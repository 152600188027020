import qs from 'query-string';

const facebookOnClick = (applicationOffer) => {
  const urlParams = qs.stringify({
    quote: applicationOffer.socialMediaShareText,
    u: applicationOffer.acceptanceSocialMediaLink,
  });

  window.open(
    `https://www.facebook.com/sharer/sharer.php?${urlParams}`,
    '_blank'
  );
};

const linkedinOnClick = (applicationOffer) => {
  const urlParams = qs.stringify({
    url: applicationOffer.acceptanceSocialMediaLink,
  });

  window.open(
    `https://www.linkedin.com/sharing/share-offsite/?${urlParams}`,
    '_blank'
  );
};

const twitterOnClick = (applicationOffer) => {
  const urlParams = qs.stringify({
    text: applicationOffer.socialMediaShareText,
    url: applicationOffer.acceptanceSocialMediaLink,
  });

  window.open(`https://twitter.com/share?${urlParams}`, '_blank');
};

export default (props, vars) => {
  return [
    {
      icon: 'link',
      caption: 'Copy',
      onClick: () =>
        navigator.clipboard.writeText(
          vars?.useapplicationoffer2?.applicationOffer
            ?.acceptanceSocialMediaLink
        ),
    },
    {
      icon: 'facebook',
      label: 'Facebook',
      onClick: () =>
        facebookOnClick(vars?.useapplicationoffer2?.applicationOffer),
    },
    {
      icon: 'linkedin',
      label: 'Linkedin',
      onClick: () =>
        linkedinOnClick(vars?.useapplicationoffer2?.applicationOffer),
    },
    {
      icon: 'twitter',
      label: 'Twitter',
      onClick: () =>
        twitterOnClick(vars?.useapplicationoffer2?.applicationOffer),
    },
  ];
};
