/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import TooltipWrapperCode from '../TooltipWrapperCode';

const TooltipWrapper = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '17_77',
    propsArg,
    vars,
    variants
  );

  return (
    <TooltipWrapperCode
      {...props}
      className={props.className}
      ref={ref}
      key={getKey(report, 'v1012_20')}
    />
  );
});

TooltipWrapper.displayName = 'TooltipWrapper';

export default TooltipWrapper;
