const prevBindFn = (students) =>
  students.sort((a, b) => {
    if (!!a.photo && !b.photo) return -1;
    else if (!a.photo && !!b.photo) return +1;
    else return 0;
  });

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(props?.['students'])
    : prevBindFn;
