import useSWR from 'swr';
import ApplicationOffer from '../../domain/Application/offer';

export default ({ children, applicationID, loginString, options = {} }) => {
  const { data, error, mutate, isValidating } = useSWR(
    applicationID && loginString
      ? [`/v2/applications/${applicationID}/offer`, loginString]
      : null,
    options
  );

  return children
    ? typeof children === 'function'
      ? children({
          applicationOffer:
            !error && !!data ? new ApplicationOffer(data) : null,
          isLoading: !error && !data,
          isError: error,
          mutate,
          isValidating,
        })
      : children
    : null;
};
