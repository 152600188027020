import axios from 'axios';

const DEFAULT_API_URL = 'https://apidev.paragonone.com/';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const get = async (path, options = {}) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL || DEFAULT_API_URL}${path}`,
    {
      ...options,
      headers: { ...defaultHeaders, ...options.headers },
    }
  );
  return response;
};

export const post = async (path, data, options = {}) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL || DEFAULT_API_URL}${path}`,
    data,
    {
      ...options,
      headers: { ...defaultHeaders, ...options.headers },
    }
  );
  return response;
};

export const swrFetcher = async (path, authToken) => {
  const options = {};
  if (authToken) options.headers = { Authorization: authToken };

  const response = await get(path, options);
  return response.data;
};

export default {
  get,
  post,
  swrFetcher,
};
