import { STAGES } from '@/src/domain/Application/stages';
import ApplicationOfferApi from '@/src/services/ApplicationOffer';

const prevBindFn = (handleSubmit, mutate) => {
  return handleSubmit(async (data) => {
    const { offerID, loginString, ...metadata } = data;
    const api = new ApplicationOfferApi(offerID, loginString);

    try {
      await api.acceptOffer(metadata);

      mutate((applicationOfferData) => ({
        ...applicationOfferData,
        stage_id: STAGES.ACCEPTED,
      }));
    } catch (err) {
      console.error(err);
    }
  });
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        vars?.['useform2']?.['handleSubmit'],
        vars?.['useapplicationoffer2']?.['mutate']
      )
    : prevBindFn;
