export const VIDEO_SOURCES = {
  LOOM: 'loom',
  YOUTUBE: 'youtube',
  GDRIVE: 'google_drive',
  OTHER: undefined,
};

export const extractVideoSource = (url) => {
  try {
    const parsedURL = new URL(url);
    const hostname = parsedURL.hostname.replace('www.', '');

    switch (hostname) {
      case 'loom.com':
        return VIDEO_SOURCES.LOOM;
      case 'drive.google.com':
        return VIDEO_SOURCES.GDRIVE;
      case 'youtube.com':
      case 'youtu.be':
        return VIDEO_SOURCES.YOUTUBE;
      default:
        return VIDEO_SOURCES.OTHER;
    }
  } catch (err) {
    return VIDEO_SOURCES.OTHER;
  }
};

export const extractVideoIDBySource = (url, source) => {
  try {
    const parsedURL = new URL(url);

    switch (source) {
      case VIDEO_SOURCES.LOOM:
        return parsedURL.pathname.split('/').slice(-1)[0];
      case VIDEO_SOURCES.YOUTUBE:
        // eslint-disable-next-line
        const queryParamsID = parsedURL.searchParams.get('v');
        return queryParamsID
          ? queryParamsID
          : parsedURL.pathname.split('/').slice(-1)[0];
      case VIDEO_SOURCES.GDRIVE:
        return parsedURL.pathname.split('/').slice(-2)[0];
      default:
        return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const isValidURL = (url) => {
  try {
    const parsedURL = new URL(url);
    return parsedURL.protocol === 'http:' || parsedURL.protocol === 'https:';
  } catch (_) {
    return false;
  }
};
