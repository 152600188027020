/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import UseRef from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseRef';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import classNameFn from './code/box/classNameFn.js';
import UseOutsideClickRef from '@clutch-marketplace/react-hooks/dist/cjs/components/UseOutsideClickRef';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import callbackFn from './code/useoutsideclickref1/callbackFn.js';
import styles from './styles.module.scss';

const Backdrop = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport('27_2', propsArg, vars, variants);

  return (
    <UseRef
      className={props.className}
      ref={ref}
      key={getKey(report, '63_318')}
    >
      {(
        (prevReport) =>
        (...args) => {
          const [report, vars] = b.getReport(
            '63_318',
            'children',
            prevReport,
            'useref1',
            args
          );

          return (
            <Box
              className={b.cx([
                styles.clt_27_47,
                b.tryCatch(classNameFn, props, vars),
              ])}
              tag="div"
              ref={vars?.useref1}
              key={getKey(report, '27_47')}
            >
              <UseOutsideClickRef
                callback={b.tryCatch(callbackFn, props, vars)}
                key={getKey(report, '28_11')}
                data-d={getKey(report, '28_11')}
              >
                {(
                  (prevReport) =>
                  (...args) => {
                    const [report, vars] = b.getReport(
                      '28_11',
                      'children',
                      prevReport,
                      'useoutsideclickref1',
                      args
                    );

                    return (
                      <Element
                        tag="div"
                        ref={vars?.useoutsideclickref1?.['0']}
                        key={getKey(report, '28_19')}
                      >
                        {getKey(
                          report,
                          '27_0_children',
                          null,
                          '__CLUTCH_SLOT__'
                        ) && typeof props.children === 'function'
                          ? props.children({})
                          : props.children}
                      </Element>
                    );
                  }
                )(report)}
              </UseOutsideClickRef>
            </Box>
          );
        }
      )(report)}
    </UseRef>
  );
});

Backdrop.displayName = 'Backdrop';

export default Backdrop;
