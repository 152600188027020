import React from 'react';

/**
 *  Logic created in this function will be
 *  executed in the component definition
 *  it's a good place to, for example, use hooks
 *  and abstract logic from the component's
 *  composition
 *
 *  Returning an Object will make it available
 *  as vars in your component
 */
export default (props) => {
  const [isInViewOfferDetailsMode, setIsInViewOfferDetailsMode] =
    React.useState(false);

  return {
    isInViewOfferDetailsMode,
    setIsInViewOfferDetailsMode,
  };
};
