export const STAGES = {
  APPLIED: 10,
  VIDEO_OFFERED: 20,
  VIDEO_COMPLETED: 30,
  LIVE_OFFERED: 40,
  LIVE_SCHEDULED: 50,
  LIVE_COMPLETED: 60,
  OFFER_SENT: 70,
  ACCEPTED: 80,
  ENROLLED: 100,
  REJECTED: 110,
  DUPLICATE_PAUSED: 120,
  STUDENT_DECLINED: 130,
  STUDENT_NOT_RESPONSIVE: 140,
  CANCELLED: 200,
};

export default STAGES;
