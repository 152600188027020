/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import ForEach from '@clutch-marketplace/logic/dist/cjs/components/ForEach';
import StudentCard from '../StudentCard';
import keyFn from './code/studentCard/keyFn.js';
import itemsFn from './code/defaultforeach1/itemsFn.js';
import Popup from '../Popup';
import UseKey from '@clutch-marketplace/react-hooks/dist/cjs/components/UseKey';
import StudentProfile from '../StudentProfile';
import studentFn from './code/studentProfile/studentFn.js';
import callbackFn from './code/useKey/callbackFn.js';
import openFn from './code/popupstudentprofile/openFn.js';
import onCloseFn from './code/popupstudentprofile/onCloseFn.js';
import Button from '@clutch-marketplace/web-elements/dist/cjs/components/Button';
import Icon from '../Icon';
import onClickFn from './code/button/onClickFn.js';
import styleFn from './code/button/styleFn.js';
import onClickFn1 from './code/button/onClickFn-1.js';
import styleFn1 from './code/button/styleFn-1.js';
import StudentPresentation from '../StudentPresentation';
import studentFn1 from './code/studentPresentation/studentFn-1.js';
import openFn1 from './code/popupstudentpresentation/openFn-1.js';
import onCloseFn1 from './code/popupstudentpresentation/onCloseFn-1.js';
import Button1 from '../Button';
import onClickFn2 from './code/button/onClickFn-2.js';
import styles from './styles.module.scss';

const StudentsList = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '14_259',
    propsArg,
    vars,
    variants
  );

  return (
    <UseState
      className={props.className}
      ref={ref}
      key={getKey(report, '60_8')}
    >
      {(
        (prevReport) =>
        (...args) => {
          const [report, vars] = b.getReport(
            '60_8',
            'children',
            prevReport,
            'usestatepresentationpopupopened',
            args
          );

          return (
            <UseState key={getKey(report, '53_276')}>
              {(
                (prevReport) =>
                (...args) => {
                  const [report, vars] = b.getReport(
                    '53_276',
                    'children',
                    prevReport,
                    'usestateprofilepopupopened',
                    args
                  );

                  return [
                    <Box
                      tag="div"
                      className={styles.clt_14_101}
                      key={getKey(report, '14_101')}
                    >
                      <ForEach
                        items={b.tryCatch(itemsFn, props, vars)}
                        key={getKey(report, '14_120')}
                      >
                        {(
                          (prevReport) =>
                          (...args) => {
                            const [report, vars] = b.getReport(
                              '14_120',
                              'children',
                              prevReport,
                              'defaultforeach1',
                              args
                            );

                            return (
                              <StudentCard
                                onOpenProfile={
                                  vars?.usestateprofilepopupopened?.setState
                                }
                                student={vars?.defaultforeach1?.item}
                                onOpenPresentation={
                                  vars?.usestatepresentationpopupopened
                                    ?.setState
                                }
                                showPresentations={props?.showPresentations}
                                key={getKey(
                                  report,
                                  '14_283',
                                  b.tryCatch(keyFn, props, vars)
                                )}
                                data-d={getKey(
                                  report,
                                  '14_283',
                                  b.tryCatch(keyFn, props, vars)
                                )}
                              />
                            );
                          }
                        )(report)}
                      </ForEach>
                    </Box>,
                    <Popup
                      open={b.tryCatch(openFn, props, vars)}
                      onClose={b.tryCatch(onCloseFn, props, vars)}
                      hideCloseButton={false}
                      wrapperClassName="genClass46"
                      before={
                        <Button
                          onClick={b.tryCatch(onClickFn, props, vars)}
                          style={{
                            visibility: b.tryCatch(styleFn, props, vars),
                          }}
                          type="button"
                          disabled={false}
                          key={getKey(report, '55_14')}
                        >
                          <Icon
                            name="expand_more"
                            className={styles.clt_55_18}
                            key={getKey(report, '55_18')}
                            data-d={getKey(report, '55_18')}
                          />
                        </Button>
                      }
                      after={
                        <Button
                          onClick={b.tryCatch(onClickFn1, props, vars)}
                          style={{
                            visibility: b.tryCatch(styleFn1, props, vars),
                          }}
                          type="button"
                          disabled={false}
                          name={undefined}
                          key={getKey(report, '56_165')}
                        >
                          <Icon
                            name="expand_more"
                            key={getKey(report, '56_166')}
                            data-d={getKey(report, '56_166')}
                          />
                        </Button>
                      }
                      className={styles.clt_53_277}
                      key={getKey(report, '53_277')}
                      data-d={getKey(report, '53_277')}
                    >
                      {(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '53_277',
                            'children',
                            prevReport,
                            'popupstudentprofile',
                            args
                          );

                          return (
                            <UseKey
                              values={['ArrowLeft', 'ArrowRight']}
                              callback={b.tryCatch(callbackFn, props, vars)}
                              key={getKey(report, '56_168')}
                              data-d={getKey(report, '56_168')}
                            >
                              {(
                                (prevReport) =>
                                (...args) => {
                                  const [report, vars] = b.getReport(
                                    '56_168',
                                    'children',
                                    prevReport,
                                    'useKey',
                                    args
                                  );

                                  return (
                                    <StudentProfile
                                      student={b.tryCatch(
                                        studentFn,
                                        props,
                                        vars
                                      )}
                                      onOpenPresentation={
                                        vars?.usestatepresentationpopupopened
                                          ?.setState
                                      }
                                      showPresentations={
                                        props?.showPresentations
                                      }
                                      key={getKey(report, '53_326')}
                                      data-d={getKey(report, '53_326')}
                                    />
                                  );
                                }
                              )(report)}
                            </UseKey>
                          );
                        }
                      )(report)}
                    </Popup>,
                    <Popup
                      open={b.tryCatch(openFn1, props, vars)}
                      onClose={b.tryCatch(onCloseFn1, props, vars)}
                      hideCloseButton={false}
                      wrapperClassName="genClass45"
                      after={
                        <Button1
                          text="Popout Video"
                          loading={false}
                          disabled={false}
                          rounded
                          size="small"
                          color="info"
                          renderEnd={
                            <Icon
                              name="open_in_new"
                              className={styles.clt_63_435}
                              key={getKey(report, '63_435')}
                              data-d={getKey(report, '63_435')}
                            />
                          }
                          onClick={b.tryCatch(onClickFn2, props, vars)}
                          className={styles.clt_63_396}
                          key={getKey(report, '63_396')}
                          data-d={getKey(report, '63_396')}
                        />
                      }
                      className={styles.clt_60_0}
                      key={getKey(report, '60_0')}
                      data-d={getKey(report, '60_0')}
                    >
                      {(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '60_0',
                            'children',
                            prevReport,
                            'popupstudentpresentation',
                            args
                          );

                          return (
                            <StudentPresentation
                              student={b.tryCatch(studentFn1, props, vars)}
                              key={getKey(report, '60_81')}
                              data-d={getKey(report, '60_81')}
                            />
                          );
                        }
                      )(report)}
                    </Popup>,
                  ];
                }
              )(report)}
            </UseState>
          );
        }
      )(report)}
    </UseState>
  );
});

StudentsList.displayName = 'StudentsList';

export default StudentsList;
