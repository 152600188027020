import * as b from '@clutch-creator/bridge';
import Context from './components/Context';
import PublicApp from './components/PublicApp';

import { SWRConfig } from 'swr';
import { swrFetcher } from './utils/api';

import './stylesheets/app.css';
import './stylesheets/fonts.css';
import './stylesheets/reset.css';

const PublicPages = () => {
  return (
    <Context>
      {(propsArg, ref) => {
        const variants = {};
        const theme = b.useTheme();
        const vars = { theme };
        const [report, getKey, props] = b.useReport(
          '20_75',
          propsArg,
          vars,
          variants
        );

        return (
          <PublicApp
            key={getKey(report, '20_118')}
            data-d={getKey(report, '20_118')}
          />
        );
      }}
    </Context>
  );
};

const App = () => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: true,
        fetcher: swrFetcher,
      }}
    >
      <PublicPages />
    </SWRConfig>
  );
};

App.displayName = 'Public Pages';

export default App;
