import { capitalizeWord } from '@/src/utils/strings';

const prevBindFn = (ApplicationOffer) => {
  return `Keep an eye out for an email on how you can prepare to hit the ground running for your ${
    ApplicationOffer.job_name
  }.\nWe'll also separately share your login details on the first day of your ${capitalizeWord(
    ApplicationOffer.terms.type
  )}!`;
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(props?.['applicationOffer'])
    : prevBindFn;
