import { format } from 'date-fns';

export default (props, vars) => {
  const applicationOffer = vars?.useapplicationoffer2?.applicationOffer;
  if (!applicationOffer) return '';

  const { acceptance_date } = applicationOffer;
  return acceptance_date
    ? `You've accepted this offer on ${format(
        new Date(acceptance_date),
        'MMM dd, yyy'
      )}`
    : `You've accepted this offer`;
};
