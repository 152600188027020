/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import _404 from '../404';

const PortfolioPage = React.forwardRef((propsArg, ref) => {
  return <_404 />;
});

PortfolioPage.displayName = 'PortfolioPage';

export default PortfolioPage;
