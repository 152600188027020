/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import * as b from '@clutch-creator/bridge';
import Favicon from '@clutch-marketplace/University-of-Houston---Mobile-App-Development-Fall-2021.University-of-Houston---Mobile-App-Development-Fall-2021/dist/cjs/components/Favicon';
import Fragment from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/Fragment';
import Helmet from '@clutch-marketplace/helmet/dist/cjs/components/Helmet';
import HelmetMeta from '@clutch-marketplace/helmet/dist/cjs/components/HelmetMeta';
import React from 'react';
import childrenFn from './code/fragment/childrenFn.js';
import styles from './styles.module.scss';

const Context = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '17eb1df8d6bfae252001b009',
    propsArg,
    vars,
    variants
  );

  return (
    <>
      <Fragment
        className={b.cx([styles.clt_17eb1f7107efae252001b069, props.className])}
        ref={ref}
      />
      <HelmetMeta
        charset="UTF-8"
        key={getKey(report, '17eb252a995fae252001b030')}
      />
      <HelmetMeta
        content="width=device-width, initial-scale=1.0"
        name="viewport"
        key={getKey(report, '17eb252ccfefae252001b031')}
      />
      <Fragment>{b.tryCatch(childrenFn, props, vars)}</Fragment>
      <Favicon
        faviconImageURI="https://cdn.paragonone.com/assets/images/extern-favicon.png"
        key={getKey(report, '24_282')}
        data-d={getKey(report, '24_282')}
      />
      <Helmet
        titleTemplate="%s"
        defaultTitle="Extern"
        lang="en"
        key={getKey(report, '24_289')}
      />
    </>
  );
});

Context.displayName = 'Context';

export default Context;
