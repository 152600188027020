export const STEPS = {
  APPLIED: 'APPLIED',
  VIDEO_OFFERED: 'VIDEO_OFFERED',
  VIDEO_COMPLETED: 'VIDEO_COMPLETED',
  LIVE_OFFERED: 'LIVE_OFFERED',
  LIVE_SCHEDULED: 'LIVE_SCHEDULED',
  LIVE_COMPLETED: 'LIVE_COMPLETED',
  OFFER_SENT: 'OFFER_SENT',
  ACCEPTED_OR_DECLINED: 'ACCEPTED_OR_DECLINED',
};

export default STEPS;
