/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import classNameFn from './code/box/classNameFn.js';
import conditionFn from './code/if/conditionFn.js';
import Image from '@clutch-marketplace/web-elements/dist/cjs/components/Image';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import conditionFn1 from './code/if/conditionFn-1.js';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import conditionFn2 from './code/if/conditionFn-2.js';
import classNameFn1 from './code/box/classNameFn-1.js';
import classNameFn2 from './code/box/classNameFn-2.js';
import valueFn from './code/box/valueFn.js';
import textFn from './code/text/textFn.js';
import Button from '@clutch-marketplace/web-elements/dist/cjs/components/Button';
import onClickFn from './code/defaultbutton10/onClickFn.js';
import classNameFn3 from './code/defaultbutton10/classNameFn-3.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import styles from './styles.module.scss';

const ApplicationCard = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '99_825',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_98_248, props.className])}
      ref={ref}
      key={getKey(report, '98_248')}
    >
      <Box
        className={b.cx([
          styles.clt_111_2,
          b.tryCatch(classNameFn, props, vars),
        ])}
        tag="div"
        key={getKey(report, '111_2')}
      >
        <Box
          tag="div"
          className={styles.clt_111_16}
          key={getKey(report, '111_16')}
        >
          {getKey(report, '111_0', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn, props, vars) ? (
            <Image
              src={props?.companyLogo}
              className={styles.clt_v200_103588}
              key={getKey(report, 'v200_103588')}
            />
          ) : null}
          <Element key={getKey(report, '100_118')}>
            {getKey(report, '100_117', null, '__CLUTCH_IF__') &&
            b.tryCatch(conditionFn1, props, vars) ? (
              <Text
                className={b.cx([styles.clt_v200_103321, 'status-tag'])}
                text="Accepted"
                tag="span"
                key={getKey(report, 'v200_103321')}
              />
            ) : null}
            {getKey(report, '100_125', null, '__CLUTCH_IF__') &&
            b.tryCatch(conditionFn2, props, vars) ? (
              <Text
                className={b.cx([styles.clt_v200_103337, 'status-tag'])}
                text="Rejected"
                tag="span"
                key={getKey(report, 'v200_103337')}
              />
            ) : null}
          </Element>
        </Box>
        <Text
          text={props?.title}
          tag="span"
          className={styles.clt_99_591}
          key={getKey(report, '99_591')}
        />
      </Box>
      <Box
        className={b.cx([
          styles.clt_99_582,
          b.tryCatch(classNameFn1, props, vars),
        ])}
        tag="div"
        key={getKey(report, '99_582')}
      >
        <Box
          tag="div"
          className={styles.clt_104_825}
          key={getKey(report, '104_825')}
        >
          {getKey(report, '102_57', null, '__CLUTCH_SLOT__') &&
          typeof props.body === 'function'
            ? props.body({})
            : props.body}
        </Box>
        <Box
          tag="div"
          className={styles.clt_99_686}
          key={getKey(report, '99_686')}
        >
          <Box
            className={b.cx([
              styles.clt_99_790,
              b.tryCatch(classNameFn2, props, vars),
            ])}
            tag="progress"
            value={b.tryCatch(valueFn, props, vars)}
            max="8"
            key={getKey(report, '99_790')}
          />
          <Box
            tag="div"
            className={styles.clt_99_699}
            key={getKey(report, '99_699')}
          >
            <Text
              text={b.tryCatch(textFn, props, vars)}
              tag="span"
              className={styles.clt_99_713}
              key={getKey(report, '99_713')}
            />
            <Button
              onClick={b.tryCatch(onClickFn, props, vars)}
              className={b.cx([
                styles.clt_99_714,
                b.tryCatch(classNameFn3, props, vars),
              ])}
              type="button"
              disabled={false}
              key={getKey(report, '99_714')}
            >
              <Text
                text="View Progress"
                tag="span"
                key={getKey(report, '99_715')}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      {getKey(report, '99_581', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn3, props, vars) ? (
        <Box
          tag="div"
          className={styles.clt_v200_103304}
          key={getKey(report, 'v200_103304')}
        >
          {getKey(report, '100_107', null, '__CLUTCH_SLOT__') &&
          typeof props.footer === 'function'
            ? props.footer({})
            : props.footer}
        </Box>
      ) : null}
    </Box>
  );
});

ApplicationCard.displayName = 'ApplicationCard';

export default ApplicationCard;
