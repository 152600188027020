/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import dangerouslySetInnerHTMLFn from './code/element/dangerouslySetInnerHTMLFn.js';

const HTMLElement = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '17_222',
    propsArg,
    vars,
    variants
  );

  return (
    <Element
      tag={props?.tag}
      dangerouslySetInnerHTML={b.tryCatch(
        dangerouslySetInnerHTMLFn,
        props,
        vars
      )}
      className={b.cx([props?.className, props.className])}
      onClick={props?.onClick}
      ref={ref}
      key={getKey(report, '17_232')}
    />
  );
});

HTMLElement.displayName = 'HTMLElement';

export default HTMLElement;
