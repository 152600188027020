import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';

import { AnimatePresence, motion } from 'framer-motion';

export default function TooltipClickWrapper({
  children,
  label,
  beforeLabel,
  afterLabel,
  placement = 'bottom',
  timer,
  onClick,
  className = 'tooltipWrapper-label',
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context, refs, update } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [offset(5), flip(), shift({ padding: 8 })],
    });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  useEffect(() => {
    if (timer && open) {
      const timeout = setTimeout(() => {
        setOpen(false);
      }, timer);

      return () => clearTimeout(timeout);
    }
  }, [open]);

  const referenceProps = getReferenceProps({ ref: reference });

  return (
    <React.Fragment>
      {isValidElement(children) &&
        cloneElement(children, {
          ...referenceProps,
          onClick: (e) => {
            referenceProps.onClick(e);
            if (onClick) onClick(e);
          },
        })}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, scale: 0.85 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 300 }}
            {...getFloatingProps({
              ref: floating,
              className,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            {beforeLabel || null}
            <span>{label}</span>
            {afterLabel || null}
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
}
