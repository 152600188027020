import { format } from 'date-fns';
import STAGES from './stages';

export const OFFER_LETTER_FORMS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
};

export default class ApplicationOffer {
  constructor(data) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    if (this.is_neutral === undefined)
      this.is_neutral = !data.show_company_logo;

    this.terms = this.generateTerminology();

    this.STAGES = STAGES;
    this.OFFER_LETTER_FORMS = OFFER_LETTER_FORMS;
  }

  formatDate2Form(date) {
    return format(date, 'yyyy/MM/dd');
  }

  formatDate2Details(date) {
    return format(date, 'yyyy-MM-dd');
  }

  generateTerminology() {
    switch (this.job_type) {
      case 'ex':
        return {
          type: 'externship',
          a_type: 'an externship',
          a_student: 'an extern',
          student: 'extern',
        };
      case 'pr':
        return {
          type: 'practicum',
          a_type: 'a practicum',
          a_student: 'a student',
          student: 'student',
        };
      default:
        return {
          type: 'externship',
          a_type: 'an externship',
          a_student: 'an extern',
          student: 'extern',
        };
    }
  }

  get socialMediaShareText() {
    return `I am excited to share that I've been accepted for a Remote Externship with ${this.company_name}! If you're interested, you can apply here too!`;
  }

  get acceptanceSocialMediaLink() {
    const cdnURL = new URL(this.acceptance_image_for_social_media_url);
    const imageID = cdnURL?.pathname?.split('/')?.at(-1)?.split('.')[0];

    return `https://${window.location.hostname}/offer/acceptance-image/${imageID}`;
  }
}
