const prevBindFn = (activeTab) => {
  switch (activeTab) {
    case 'current':
      return 0;
    case 'past':
      return 1;
    default:
      return undefined;
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['usestateactivetab']?.['state'])
    : prevBindFn;
