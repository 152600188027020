import Api from '@/src/utils/api';

export default class LookbooksApi {
  constructor(type) {
    this.type = type;
  }

  async submitContact(firstName, email, company, title) {
    const response = await Api.post(`/v2/lookbooks/${this.type}/contact/`, {
      firstName,
      email,
      company,
      title,
    });
    return response.data;
  }
}
