import { format } from 'date-fns';

const formatDate = (dateStr) => format(new Date(dateStr), 'MMM dd, yyy');

const prevBindFn = (startDateStr, endDateStr) => {
  return `${formatDate(startDateStr)} — ${formatDate(endDateStr)}`;
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        vars?.['uselookbook2']?.['lookbook']?.['about']?.['first_start_date'],
        vars?.['uselookbook2']?.['lookbook']?.['about']?.['last_end_date']
      )
    : prevBindFn;
