const prevBindFn = (activeTab) => {
  switch (activeTab) {
    case 'current':
      return 'Current Externs';
    case 'past':
      return 'Past Externs';
    default:
      return '';
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['usestateactivetab']?.['state'])
    : prevBindFn;
