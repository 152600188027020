/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Iframev2 from '@clutch-marketplace/paragonone.iframe/dist/cjs/components/Iframev2';
import React from 'react';
import srcFn from './code/iframev2/srcFn.js';

const EmbedVideoYoutube = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '61_101',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      style={{
        position: 'relative',
        paddingBottom: '62.5%',
        height: 0,
      }}
      tag="div"
      className={props.className}
      ref={ref}
      key={getKey(report, '61_85')}
    >
      <Iframev2
        src={b.tryCatch(srcFn, props, vars)}
        frameBorder="0"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        webkitallowfullscreen="webkitallowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        allowFullScreen="allowFullScreen"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        key={getKey(report, '61_86')}
      />
    </Box>
  );
});

EmbedVideoYoutube.displayName = 'EmbedVideoYoutube';

export default EmbedVideoYoutube;
