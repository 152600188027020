/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import conditionFn from './code/if/conditionFn.js';
import TextInput from '@clutch-marketplace/web-elements/dist/cjs/components/TextInput';
import classNameFn from './code/textInput/classNameFn.js';
import _registerFn from './code/textInput/_registerFn.js';
import conditionFn1 from './code/if/conditionFn-1.js';
import TextAreav2 from '../TextAreav2';
import rowsFn from './code/textAreav2/rowsFn.js';
import _registerFn1 from './code/textAreav2/_registerFn-1.js';
import styles from './styles.module.scss';

const TextField = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '28_193',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_28_260, props.className])}
      ref={ref}
      key={getKey(report, '28_260')}
    >
      {getKey(report, '28_271', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn, props, vars) ? (
        <TextInput
          className={b.cx([
            styles.clt_v200_102805,
            b.tryCatch(classNameFn, props, vars),
          ])}
          onChange={props?.onChange}
          name={props?.name}
          disabled={props?.disabled}
          placeholder={props?.placeholder}
          required={props?.required}
          type={props?.type}
          value={props?.value}
          {...b.tryCatch(_registerFn, props, vars)}
          key={getKey(report, 'v200_102805')}
        />
      ) : null}
      {getKey(report, '91_19', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn1, props, vars) ? (
        <TextAreav2
          readOnly={props?.readonly}
          maxLength={props?.maxLength}
          className={props?.className}
          onChange={props?.onChange}
          required={props?.required}
          placeholder={props?.placeholder}
          name={props?.name}
          disabled={props?.disabled}
          value={props?.value}
          rows={b.tryCatch(rowsFn, props, vars)}
          {...b.tryCatch(_registerFn1, props, vars)}
          key={getKey(report, 'v200_103750')}
        />
      ) : null}
    </Box>
  );
});

TextField.displayName = 'TextField';

export default TextField;
