/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import classNameFn from './code/box/classNameFn.js';
import styleFn from './code/box/styleFn.js';
import styleFn1 from './code/box/styleFn-1.js';
import styleFn2 from './code/box/styleFn-2.js';
import styleFn3 from './code/box/styleFn-3.js';
import styleFn4 from './code/box/styleFn-4.js';
import styleFn5 from './code/box/styleFn-5.js';

const Loading = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '17_164',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      className={b.cx([b.tryCatch(classNameFn, props, vars), props.className])}
      style={{
        borderBottom: b.tryCatch(styleFn, props, vars),
        borderTop: b.tryCatch(styleFn1, props, vars),
        width: b.tryCatch(styleFn2, props, vars),
        height: b.tryCatch(styleFn3, props, vars),
        borderRight: b.tryCatch(styleFn4, props, vars),
        borderLeft: b.tryCatch(styleFn5, props, vars),
      }}
      tag="div"
      ref={ref}
      key={getKey(report, '17_172')}
    />
  );
});

Loading.displayName = 'Loading';

export default Loading;
