const prevBindFn = () => {
  const timeoutID = window.setTimeout(() => {
    window.location.replace('https://www.extern.com/externships');
  }, 500);

  return () => window.clearTimeout(timeoutID);
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? (...args) => prevBindFn(...args)
    : prevBindFn;
