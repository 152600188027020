export default (_, students, currentID, setStudentID, offset) => {
  if (currentID) {
    const currentIdx = students.findIndex((s) => s.id === currentID);
    const nextIdx = Math.max(
      Math.min(currentIdx + offset, students.length - 1),
      0
    );

    setStudentID(students[nextIdx].id);
  }
};
