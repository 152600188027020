import navigation from '../../../../handlers/ProfilePopup/navigation';

const prevBindFn = (e, students, currentID, setStudentID) => {
  if (currentID) {
    const offset =
      e.code === 'ArrowRight' ? +1 : e.code === 'ArrowLeft' ? -1 : undefined;

    if (offset) {
      navigation(e, students, currentID, setStudentID, offset);
    }
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? (event) =>
        prevBindFn(
          event,
          props?.['students'],
          vars?.['usestateprofilepopupopened']?.['state'],
          vars?.['usestateprofilepopupopened']?.['setState']
        )
    : prevBindFn;
