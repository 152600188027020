/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Button from '@clutch-marketplace/web-elements/dist/cjs/components/Button';
import IfElse from '@clutch-marketplace/logic/dist/cjs/components/IfElse';
import Loading from '../Loading';
import sizeFn from './code/loading/sizeFn.js';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import conditionFn from './code/ifElse/conditionFn.js';
import onClickFn from './code/button/onClickFn.js';
import classNameFn from './code/button/classNameFn.js';
import styles from './styles.module.scss';

const Button2 = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '25_53',
    propsArg,
    vars,
    variants
  );

  return (
    <Button
      onClick={b.tryCatch(onClickFn, props, vars)}
      className={b.cx([
        styles.clt_25_82,
        b.tryCatch(classNameFn, props, vars),
        props.className,
      ])}
      style={props?.style}
      type="button"
      disabled={props?.disabled}
      ref={ref}
      key={getKey(report, '25_82')}
    >
      {getKey(report, '25_51_renderStart', null, '__CLUTCH_SLOT__') &&
      typeof props.renderStart === 'function'
        ? props.renderStart({})
        : props.renderStart}
      <IfElse
        if={
          <Loading
            color="#ffffff"
            size={b.tryCatch(sizeFn, props, vars)}
            thickness="3px"
            className={styles.clt_25_138}
            key={getKey(report, '25_138')}
            data-d={getKey(report, '25_138')}
          />
        }
        else={
          <Text
            className={b.cx([styles.clt_25_83, props?.size])}
            text={props?.text}
            tag="span"
            key={getKey(report, '25_83')}
          />
        }
        condition={b.tryCatch(conditionFn, props, vars)}
        key={getKey(report, '25_135')}
      />
      {getKey(report, '25_51_renderEnd', null, '__CLUTCH_SLOT__') &&
      typeof props.renderEnd === 'function'
        ? props.renderEnd({})
        : props.renderEnd}
    </Button>
  );
});

Button2.displayName = 'Button';

export default Button2;
