/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Backdrop from '../Backdrop';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import conditionFn from './code/if/conditionFn.js';
import HTMLElement from '../HTMLElement';
import styles from './styles.module.scss';

const Popup = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '28_32',
    propsArg,
    vars,
    variants
  );

  return (
    <Backdrop
      onClick={props?.onClose}
      open={props?.open}
      className={props.className}
      ref={ref}
      key={getKey(report, '28_74')}
      data-d={getKey(report, '28_74')}
    >
      <Box
        className={props?.wrapperClassName}
        tag="div"
        key={getKey(report, '55_21')}
      >
        {getKey(report, '55_39', null, '__CLUTCH_SLOT__') &&
        typeof props.before === 'function'
          ? props.before({})
          : props.before}
        <Box
          className={b.cx([styles.clt_28_76, props?.className])}
          tag="div"
          key={getKey(report, '28_76')}
        >
          {getKey(report, '28_77', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn, props, vars) ? (
            <HTMLElement
              tag="div"
              innerHTML={
                '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.8766 15.9891L14.3297 15.982L12 13.2047L9.67266 15.9797L8.12344 15.9867C8.02031 15.9867 7.93594 15.9047 7.93594 15.7992C7.93594 15.7547 7.95234 15.7125 7.98047 15.6773L11.0297 12.0445L7.98047 8.41406C7.95215 8.37971 7.93643 8.3367 7.93594 8.29219C7.93594 8.18906 8.02031 8.10469 8.12344 8.10469L9.67266 8.11172L12 10.8891L14.3273 8.11406L15.8742 8.10703C15.9773 8.10703 16.0617 8.18906 16.0617 8.29453C16.0617 8.33906 16.0453 8.38125 16.0172 8.41641L12.9727 12.0469L16.0195 15.6797C16.0477 15.7148 16.0641 15.757 16.0641 15.8016C16.0641 15.9047 15.9797 15.9891 15.8766 15.9891Z" fill="#061237"/>\n</svg>'
              }
              onClick={props?.onClose}
              className={styles.clt_v200_102781}
              key={getKey(report, 'v200_102781')}
              data-d={getKey(report, 'v200_102781')}
            />
          ) : null}
          {getKey(report, '28_30_children', null, '__CLUTCH_SLOT__') &&
          typeof props.children === 'function'
            ? props.children({})
            : props.children}
        </Box>
        {getKey(report, '55_55', null, '__CLUTCH_SLOT__') &&
        typeof props.after === 'function'
          ? props.after({})
          : props.after}
      </Box>
    </Backdrop>
  );
});

Popup.displayName = 'Popup';

export default Popup;
