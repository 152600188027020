/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import conditionFn from './code/if/conditionFn.js';
import conditionFn1 from './code/if/conditionFn-1.js';
import EmbedVideoLoom from '../EmbedVideoLoom';
import idFn from './code/embedVideoLoom/idFn.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import EmbedVideoYoutube from '../EmbedVideoYoutube';
import idFn1 from './code/embedVideoYoutube/idFn-1.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import EmbedVideoGoogleDrive from '../EmbedVideoGoogleDrive';
import idFn2 from './code/embedVideoGoogleDrive/idFn-2.js';
import styles from './styles.module.scss';

const StudentPresentation = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '60_73',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_60_12, props.className])}
      ref={ref}
      key={getKey(report, '60_12')}
    >
      <Text
        text={b.tryCatch(textFn, props, vars)}
        tag="h3"
        className={styles.clt_60_13}
        key={getKey(report, '60_13')}
      />
      {getKey(report, '61_52', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn, props, vars) ? (
        <Box tag="div" key={getKey(report, 'v200_103061')}>
          {getKey(report, '61_76', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn1, props, vars) ? (
            <EmbedVideoLoom
              id={b.tryCatch(idFn, props, vars)}
              key={getKey(report, 'v200_103076')}
              data-d={getKey(report, 'v200_103076')}
            />
          ) : null}
          {getKey(report, '61_109', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn2, props, vars) ? (
            <EmbedVideoYoutube
              id={b.tryCatch(idFn1, props, vars)}
              key={getKey(report, 'v200_103080')}
              data-d={getKey(report, 'v200_103080')}
            />
          ) : null}
          {getKey(report, '62_10', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn3, props, vars) ? (
            <EmbedVideoGoogleDrive
              id={b.tryCatch(idFn2, props, vars)}
              key={getKey(report, 'v200_103084')}
              data-d={getKey(report, 'v200_103084')}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
});

StudentPresentation.displayName = 'StudentPresentation';

export default StudentPresentation;
