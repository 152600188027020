import { isPast } from 'date-fns';

const prevBindFn = (isOutstanding, lastEndDate) => {
  const noLongerLive = isPast(new Date(lastEndDate));
  return isOutstanding || noLongerLive ? 'Extern Alumni' : 'Externs';
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        props?.['isOutstanding'],
        vars?.['uselookbook2']?.['lookbook']?.['about']?.['last_end_date']
      )
    : prevBindFn;
