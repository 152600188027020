import LookbooksApi from '../../../../services/Lookbooks';
import { downloadCsv } from '../../../../utils/navigation';
const { Parser } = require('json2csv');

const fields = [
  {
    label: 'Student',
    value: 'full_name',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Linkedin',
    value: 'linkedinUrl',
  },
  {
    label: 'University',
    value: 'university',
  },
  {
    label: 'Graduation Year',
    value: 'grad_year',
  },
  {
    label: 'Majors',
    value: 'majors',
  },
  {
    label: 'School Type',
    value: 'school_type',
  },
  {
    label: 'Resume',
    value: 'resume_url',
  },
  {
    label: 'Externship',
    value: 'job_name',
  },
  {
    label: 'Company',
    value: 'company_name',
  },
];

const onSuccessHandler = (students, lookbookName) => {
  const json2csvParser = new Parser({ fields, withBOM: true });
  const csv = json2csvParser.parse(students);

  downloadCsv(`${lookbookName}.csv`, csv);
};

const prevBindFn = (
  handleSubmit,
  { onSuccess, onError, before, onFinally },
  code
) =>
  handleSubmit(async (formData) => {
    try {
      if (before) before(formData);

      const lookbooksApi = new LookbooksApi(code);

      await lookbooksApi.submitContact(
        formData.firstName,
        formData.workEmail,
        formData.companyName,
        formData.yourTitle
      );

      if (onSuccess) onSuccess();
    } catch (e) {
      if (onError) onError(e);
      throw e;
    } finally {
      if (onFinally) onFinally();
    }
  });

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        vars?.['useform1']?.['handleSubmit'],
        {
          onSuccess: () =>
            onSuccessHandler(
              vars?.uselookbook2?.lookbook?.students,
              vars?.uselookbook2?.lookbook?.about?.job_name
            ),
          onError: null,
        },
        props?.['code']
      )
    : prevBindFn;
