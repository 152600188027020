import ApplicationOfferApi from '@/src/services/ApplicationOffer';

const parseMetadata = (metadata) => ({
  ...metadata,
  why: metadata?.why?.value,
});

const prevBindFn = (handleSubmit, { onSuccess } = {}) => {
  return handleSubmit(async (data) => {
    const { offerID, loginString, ...metadata } = data;
    const api = new ApplicationOfferApi(offerID, loginString);
    const parsedMetadata = parseMetadata(metadata);

    try {
      await api.declineOffer(parsedMetadata);
      if (onSuccess) onSuccess();
    } catch (err) {
      console.error(err);
      throw err;
    }
  });
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['useform3']?.['handleSubmit'])
    : prevBindFn;
