import { openInNewTab } from '@/src/utils/navigation';
import { VIDEO_SOURCES } from '@/src/utils/url';

const prevBindFn = (_, student, onOpenPresentation) => {
  if (student?.finalPresentation.source === VIDEO_SOURCES.OTHER) {
    openInNewTab(student?.finalPresentation.url);
  } else {
    onOpenPresentation(student?.id);
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? (...args) =>
        prevBindFn(...args, props?.['student'], props?.['onOpenPresentation'])
    : prevBindFn;
