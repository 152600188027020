import { isPast } from 'date-fns';

const prevBindFn = (students, filter) => {
  return students.filter(({ end_date }) => {
    const isPastStudent = isPast(new Date(end_date));

    switch (filter) {
      case 'current':
        return !isPastStudent;
      case 'past':
        return isPastStudent;
      default:
        return true;
    }
  });
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        vars?.['uselookbook2']?.['lookbook']?.['students'] || [],
        !!vars?.['uselookbook2']?.['lookbook']?.['about']?.['is_pro_bono'] &&
          vars?.['usestateactivetab']?.['state']
      )
    : prevBindFn;
