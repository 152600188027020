import React, { forwardRef } from 'react';
import { pickHTMLProps } from 'pick-react-known-prop';
export default forwardRef(
  ({ tag, type, children, maxLength, ...attributes }, ref) => {
    let Tag = React.Fragment;
    let attrs = {};
    Tag = tag || 'textarea';
    attrs = { ...attributes };

    if (typeof tag === 'string') {
      attrs = pickHTMLProps(attrs);
    }
    if (ref) attrs.ref = ref;
    return (
      <Tag type={type || 'textarea'} maxLength={maxLength} {...attrs}>
        {children}
      </Tag>
    );
  }
);
