const prevBindFn = (applicationOffer) => {
  const { is_neutral, company_name, job_name, first_name } = applicationOffer;

  if (is_neutral) {
    return `Dear ${first_name},


We're delighted to extend you an offer from Extern to participate in the ${job_name}. After reviewing your application, we believe that your skills and enthusiasm make you an excellent fit for this program.
Please take the time to carefully consider this opportunity and accept or decline this offer within the next few days. Please note that the offer is time-sensitive, and we can only reserve your spot for a limited duration.
Should you have any questions about your offer, please do not hesitate to reach out.


Sincerely
Extern`;
  } else {
    return `Dear ${first_name},


We're delighted to extend you an offer from Extern to participate in the ${job_name}. After reviewing your application, we believe that your skills and enthusiasm make you an excellent fit for this program.
Please take the time to carefully consider this opportunity and accept or decline this offer within the next few days. Please note that the offer is time-sensitive, and we can only reserve your spot for a limited duration.
Should you have any questions about your offer, please do not hesitate to reach out.


Sincerely,
Extern`;
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['useapplicationoffer2']?.['applicationOffer'])
    : prevBindFn;
