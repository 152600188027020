/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Button from '@clutch-marketplace/web-elements/dist/cjs/components/Button';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import classNameFn from './code/defaultButton2/classNameFn.js';
import styles from './styles.module.scss';

const NavigationTabs = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '48_181',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      className={b.cx([styles.clt_48_40, props?.className, props.className])}
      tag="div"
      ref={ref}
      key={getKey(report, '48_40')}
    >
      {getKey(report, '48_129', null, '__CLUTCH_LOOP__') &&
        props?.items?.map((item, index, items) =>
          (
            (prevReport) =>
            (...args) => {
              const [report, vars] = b.getReport(
                '48_129',
                'children',
                prevReport,
                'defaultButton2',
                args
              );

              return (
                <Button
                  type="button"
                  className={b.cx([
                    styles.clt_v200_101085,
                    b.tryCatch(classNameFn, props, vars),
                  ])}
                  onClick={vars?.defaultButton2?.item?.onClick}
                  key={getKey(report, 'v200_101085', index)}
                >
                  <Text
                    text={vars?.defaultButton2?.item?.displayName}
                    tag="span"
                    className={styles.clt_48_130}
                    key={getKey(report, '48_130')}
                  />
                </Button>
              );
            }
          )(report)?.({ item, index, items })
        )}
    </Box>
  );
});

NavigationTabs.displayName = 'NavigationTabs';

export default NavigationTabs;
