/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Checkbox from '@clutch-marketplace/web-elements/dist/cjs/components/Checkbox';
import nameFn from './code/checkbox/nameFn.js';
import idFn from './code/checkbox/idFn.js';
import Label from '@clutch-marketplace/web-elements/dist/cjs/components/Label';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import classNameFn from './code/text/classNameFn.js';
import htmlForFn from './code/label/htmlForFn.js';
import styles from './styles.module.scss';

const CheckboxField = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '82_20',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      className={b.cx([styles.clt_82_0, props?.className, props.className])}
      tag="div"
      ref={ref}
      key={getKey(report, '82_0')}
    >
      <Checkbox
        checked={props?.checked}
        onChange={props?.onChange}
        disabled={props?.disabled}
        name={b.tryCatch(nameFn, props, vars)}
        id={b.tryCatch(idFn, props, vars)}
        {...props?.register}
        tag="input"
        key={getKey(report, '82_4')}
      />
      <Label
        htmlFor={b.tryCatch(htmlForFn, props, vars)}
        className={styles.clt_82_1}
        key={getKey(report, '82_1')}
      >
        <Text
          className={b.cx([
            styles.clt_82_2,
            b.tryCatch(classNameFn, props, vars),
          ])}
          text={props?.label}
          tag="span"
          key={getKey(report, '82_2')}
        />
      </Label>
    </Box>
  );
});

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
