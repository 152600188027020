/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import styleFn from './code/box/styleFn.js';
import conditionFn from './code/if/conditionFn.js';
import HTMLElement from '../HTMLElement';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import textFn1 from './code/text/textFn-1.js';
import textFn2 from './code/defaulttext20/textFn-2.js';
import IfElse from '@clutch-marketplace/logic/dist/cjs/components/IfElse';
import Link from '@clutch-marketplace/web-elements/dist/cjs/components/Link';
import hrefFn from './code/link/hrefFn.js';
import TooltipWrapper from '../TooltipWrapper';
import conditionFn1 from './code/ifElse/conditionFn-1.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import Icon from '../Icon';
import textFn3 from './code/text/textFn-3.js';
import conditionFn4 from './code/if/conditionFn-4.js';
import textFn4 from './code/text/textFn-4.js';
import conditionFn5 from './code/if/conditionFn-5.js';
import Button from '../Button';
import disabledFn from './code/button/disabledFn.js';
import onClickFn from './code/button/onClickFn.js';
import hideFn from './code/tooltipWrapper/hideFn.js';
import styles from './styles.module.scss';

const StudentProfile = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '53_318',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_53_278, props.className])}
      ref={ref}
      key={getKey(report, '53_278')}
    >
      <Box
        tag="div"
        className={styles.clt_53_314}
        key={getKey(report, '53_314')}
      >
        <Box
          style={{
            backgroundImage: b.tryCatch(styleFn, props, vars),
          }}
          tag="div"
          className={styles.clt_54_64}
          key={getKey(report, '54_64')}
        >
          {getKey(report, '54_65', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn, props, vars) ? (
            <HTMLElement
              tag="div"
              innerHTML='<svg width="90" height="94" viewBox="0 0 90 94" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M85.6075 76.4844C83.3969 71.2481 80.1889 66.4918 76.1622 62.4805C72.1477 58.4575 67.3922 55.2501 62.1583 53.0352C62.1114 53.0117 62.0645 53 62.0176 52.9766C69.3184 47.7031 74.0645 39.1133 74.0645 29.4219C74.0645 13.3672 61.0567 0.359375 45.002 0.359375C28.9473 0.359375 15.9395 13.3672 15.9395 29.4219C15.9395 39.1133 20.6856 47.7031 27.9864 52.9883C27.9395 53.0117 27.8926 53.0234 27.8458 53.0469C22.5958 55.2617 17.8848 58.4375 13.8418 62.4922C9.81891 66.5066 6.61143 71.2622 4.39653 76.4961C2.22062 81.6202 1.0471 87.1138 0.939502 92.6797C0.936374 92.8048 0.958312 92.9292 1.00402 93.0457C1.04973 93.1621 1.11829 93.2682 1.20566 93.3578C1.29303 93.4474 1.39744 93.5185 1.51274 93.5671C1.62804 93.6157 1.75189 93.6407 1.877 93.6406H8.90825C9.42388 93.6406 9.83403 93.2305 9.84575 92.7266C10.0801 83.6797 13.7129 75.207 20.1348 68.7852C26.7793 62.1406 35.6036 58.4844 45.002 58.4844C54.4004 58.4844 63.2247 62.1406 69.8692 68.7852C76.2911 75.207 79.9239 83.6797 80.1582 92.7266C80.17 93.2422 80.5801 93.6406 81.0957 93.6406H88.127C88.2521 93.6407 88.376 93.6157 88.4913 93.5671C88.6066 93.5185 88.711 93.4474 88.7983 93.3578C88.8857 93.2682 88.9543 93.1621 89 93.0457C89.0457 92.9292 89.0676 92.8048 89.0645 92.6797C88.9473 87.0781 87.7872 81.6289 85.6075 76.4844ZM45.002 49.5781C39.6231 49.5781 34.5606 47.4805 30.752 43.6719C26.9434 39.8633 24.8458 34.8008 24.8458 29.4219C24.8458 24.043 26.9434 18.9805 30.752 15.1719C34.5606 11.3633 39.6231 9.26562 45.002 9.26562C50.3809 9.26562 55.4434 11.3633 59.252 15.1719C63.0606 18.9805 65.1582 24.043 65.1582 29.4219C65.1582 34.8008 63.0606 39.8633 59.252 43.6719C55.4434 47.4805 50.3809 49.5781 45.002 49.5781Z" fill="#0135FF"/> </svg>'
              className={styles.clt_v200_102907}
              key={getKey(report, 'v200_102907')}
              data-d={getKey(report, 'v200_102907')}
            />
          ) : null}
        </Box>
        <Box
          tag="div"
          className={styles.clt_54_83}
          key={getKey(report, '54_83')}
        >
          <Text
            text={b.tryCatch(textFn, props, vars)}
            tag="h3"
            className={styles.clt_53_315}
            key={getKey(report, '53_315')}
          />
          <Text
            text={b.tryCatch(textFn1, props, vars)}
            tag="span"
            key={getKey(report, '54_84')}
          />
          <Text
            text={b.tryCatch(textFn2, props, vars)}
            tag="span"
            key={getKey(report, '54_89')}
          />
          <Box
            tag="div"
            className={styles.clt_54_349}
            key={getKey(report, '54_349')}
          >
            <IfElse
              if={
                <Link
                  href={b.tryCatch(hrefFn, props, vars)}
                  target="_blank"
                  className={styles.clt_54_92}
                  key={getKey(report, '54_92')}
                >
                  <HTMLElement
                    tag="div"
                    innerHTML='<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23 0.5H3C0.5 0.5 0.5 2.44687 0.5 3V23C0.5 23.5531 0.5 25.5 3 25.5H23C25.5 25.5 25.5 23.5531 25.5 23V3C25.5 3 25.5 0.5 23 0.5ZM7.91562 21.8031H4.20625V9.87187H7.91562V21.8031ZM6.0625 8.24063C5.63727 8.24063 5.22159 8.11453 4.86802 7.87828C4.51446 7.64204 4.23889 7.30626 4.07616 6.91339C3.91343 6.52053 3.87085 6.08824 3.95381 5.67118C4.03677 5.25412 4.24154 4.87103 4.54222 4.57035C4.8429 4.26966 5.226 4.06489 5.64306 3.98194C6.06012 3.89898 6.49241 3.94156 6.88527 4.10428C7.27813 4.26701 7.61391 4.54258 7.85016 4.89615C8.0864 5.24971 8.2125 5.66539 8.2125 6.09062C8.20937 7.27812 7.24688 8.24063 6.0625 8.24063ZM21.8031 21.8031H18.0969V16C18.0969 14.6156 18.0719 12.8375 16.1688 12.8375C14.2406 12.8375 13.9438 14.3437 13.9438 15.9V21.8031H10.2406V9.87187H13.7969V11.5031H13.8469C14.3406 10.5656 15.55 9.575 17.3563 9.575C21.1125 9.575 21.8031 12.0469 21.8031 15.2594V21.8031Z" fill="#0072B1"/> </svg>'
                    className={styles.clt_54_93}
                    key={getKey(report, '54_93')}
                    data-d={getKey(report, '54_93')}
                  />
                </Link>
              }
              else={
                <TooltipWrapper
                  label="This LinkedIn link isn't available."
                  wrapperClassName="genClass43"
                  beforeLabel={
                    <HTMLElement
                      tag="div"
                      innerHTML={
                        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#0135FF"/>\n<path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#0135FF"/>\n</svg>'
                      }
                      className={styles.clt_54_96}
                      key={getKey(report, '54_96')}
                      data-d={getKey(report, '54_96')}
                    />
                  }
                  className={styles.clt_54_94}
                  key={getKey(report, '54_94')}
                  data-d={getKey(report, '54_94')}
                >
                  <HTMLElement
                    tag="div"
                    innerHTML='<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23 0.5H3C0.5 0.5 0.5 2.44687 0.5 3V23C0.5 23.5531 0.5 25.5 3 25.5H23C25.5 25.5 25.5 23.5531 25.5 23V3C25.5 3 25.5 0.5 23 0.5ZM7.91562 21.8031H4.20625V9.87187H7.91562V21.8031ZM6.0625 8.24063C5.63727 8.24063 5.22159 8.11453 4.86802 7.87828C4.51446 7.64204 4.23889 7.30626 4.07616 6.91339C3.91343 6.52053 3.87085 6.08824 3.95381 5.67118C4.03677 5.25412 4.24154 4.87103 4.54222 4.57035C4.8429 4.26966 5.226 4.06489 5.64306 3.98194C6.06012 3.89898 6.49241 3.94156 6.88527 4.10428C7.27813 4.26701 7.61391 4.54258 7.85016 4.89615C8.0864 5.24971 8.2125 5.66539 8.2125 6.09062C8.20937 7.27812 7.24688 8.24063 6.0625 8.24063ZM21.8031 21.8031H18.0969V16C18.0969 14.6156 18.0719 12.8375 16.1688 12.8375C14.2406 12.8375 13.9438 14.3437 13.9438 15.9V21.8031H10.2406V9.87187H13.7969V11.5031H13.8469C14.3406 10.5656 15.55 9.575 17.3563 9.575C21.1125 9.575 21.8031 12.0469 21.8031 15.2594V21.8031Z" fill="#BFBFBF"/> </svg>'
                    className={styles.clt_54_95}
                    key={getKey(report, '54_95')}
                    data-d={getKey(report, '54_95')}
                  />
                </TooltipWrapper>
              }
              condition={b.tryCatch(conditionFn1, props, vars)}
              key={getKey(report, '54_91')}
            />
          </Box>
        </Box>
      </Box>
      {getKey(report, '54_153', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn2, props, vars) ? (
        <Box
          tag="div"
          className={styles.clt_v200_102927}
          key={getKey(report, 'v200_102927')}
        >
          {getKey(report, '54_193', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn3, props, vars) ? (
            <Box tag="div" key={getKey(report, 'v200_102975')}>
              <Box tag="div" key={getKey(report, '54_235')}>
                <Icon
                  name="badge"
                  key={getKey(report, '54_355')}
                  data-d={getKey(report, '54_355')}
                />
                <Text text="About" tag="h3" key={getKey(report, '54_236')} />
              </Box>
              <Text
                text={b.tryCatch(textFn3, props, vars)}
                tag="p"
                key={getKey(report, '54_192')}
              />
            </Box>
          ) : null}
          {getKey(report, '54_431', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn4, props, vars) ? (
            <Box tag="div" key={getKey(report, 'v200_103028')}>
              <Box tag="div" key={getKey(report, '54_433')}>
                <Icon
                  name="flag"
                  key={getKey(report, '54_435')}
                  data-d={getKey(report, '54_435')}
                />
                <Text
                  text="Externship Goals"
                  tag="h3"
                  key={getKey(report, '54_434')}
                />
              </Box>
              <Text
                text={b.tryCatch(textFn4, props, vars)}
                tag="p"
                key={getKey(report, '54_432')}
              />
            </Box>
          ) : null}
        </Box>
      ) : null}
      {getKey(report, '63_245', null, '__CLUTCH_IF__') &&
      props?.showPresentations ? (
        <TooltipWrapper
          label="This Presentation link isn't available."
          wrapperClassName="genClass44"
          beforeLabel={
            <Icon
              name="info"
              className={styles.clt_63_268}
              key={getKey(report, '63_268')}
              data-d={getKey(report, '63_268')}
            />
          }
          hide={b.tryCatch(hideFn, props, vars)}
          className={styles.clt_v200_98346}
          key={getKey(report, 'v200_98346')}
          data-d={getKey(report, 'v200_98346')}
        >
          {getKey(report, '54_445', null, '__CLUTCH_IF__') &&
          b.tryCatch(conditionFn5, props, vars) ? (
            <Button
              text="View Presentation"
              loading={false}
              disabled={b.tryCatch(disabledFn, props, vars)}
              rounded
              size="medium"
              onClick={b.tryCatch(onClickFn, props, vars)}
              color="info"
              className={styles.clt_v200_103043}
              key={getKey(report, 'v200_103043')}
              data-d={getKey(report, 'v200_103043')}
            />
          ) : null}
        </TooltipWrapper>
      ) : null}
    </Box>
  );
});

StudentProfile.displayName = 'StudentProfile';

export default StudentProfile;
