export default (props, vars) => {
  return [
    {
      label: 'Full Name',
      value: vars?.useapplicationoffer2?.applicationOffer?.full_name,
      icon: 'user',
    },
    {
      label: "Today's Date",
      value: vars?.useapplicationoffer2?.applicationOffer?.formatDate2Form?.(
        new Date()
      ),
      icon: 'calendar',
    },
    {
      label: 'Start Date',
      value: vars?.useapplicationoffer2?.applicationOffer?.formatDate2Form?.(
        new Date(vars?.useapplicationoffer2?.applicationOffer?.start_date)
      ),
      icon: 'calendar',
    },
    {
      label: 'End Date',
      value: vars?.useapplicationoffer2?.applicationOffer?.formatDate2Form?.(
        new Date(vars?.useapplicationoffer2?.applicationOffer?.end_date)
      ),
      icon: 'calendar',
    },
  ];
};
