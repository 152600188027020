import useSWR from 'swr';
import ApplicationStatus from '../../domain/Application/status';

export default ({ children, loginString, options = {} }) => {
  const { data, error, mutate, isValidating } = useSWR(
    loginString ? [`/v2/applications/status/`, loginString] : null,
    null,
    {
      ...options,
    }
  );

  return children
    ? typeof children === 'function'
      ? children({
          applicationStatus:
            !error && !!data
              ? data?.applications.map(
                  (appData) => new ApplicationStatus(appData)
                )
              : null,
          isLoading: !error && !data,
          isError: error,
          mutate,
          isValidating,
        })
      : children
    : null;
};
