import { capitalizeWord } from '@/src/utils/strings';

const prevBindFn = (applicationOffer) => {
  return `Prior to accepting your offer, it is important to know that this program is different from a standard internship in the aspect that accepted and enrolled students are asked and expected NOT to list the experience on their LinkedIn until they complete the ${capitalizeWord(
    applicationOffer.terms.type
  )} Successfully or better.`;
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['useapplicationoffer2']?.['applicationOffer'])
    : prevBindFn;
