import Api from '@/src/utils/api';

export default class ApplicationOfferApi {
  constructor(id, loginString) {
    this.id = id;
    this.loginString = loginString;
  }

  async acceptOffer(metadata) {
    const response = await Api.post(
      `/v2/applications/${this.id}/offer/accept/`,
      { ...metadata },
      { headers: { Authorization: this.loginString } }
    );
    return response.data;
  }

  async declineOffer(metadata) {
    const response = await Api.post(
      `/v2/applications/${this.id}/offer/decline/`,
      { ...metadata },
      { headers: { Authorization: this.loginString } }
    );
    return response.data;
  }
}
