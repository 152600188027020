/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import conditionFn from './code/if/conditionFn.js';
import Image from '@clutch-marketplace/web-elements/dist/cjs/components/Image';
import srcFn from './code/image/srcFn.js';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import itemsFn from './code/defaultbox145/itemsFn.js';
import conditionFn1 from './code/if/conditionFn-1.js';
import classNameFn from './code/verticalline/classNameFn.js';
import classNameFn1 from './code/circle/classNameFn-1.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import classNameFn2 from './code/box/classNameFn-2.js';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import classNameFn3 from './code/box/classNameFn-3.js';
import classNameFn4 from './code/text/classNameFn-4.js';
import textFn1 from './code/text/textFn-1.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import conditionFn4 from './code/if/conditionFn-4.js';
import textFn2 from './code/text/textFn-2.js';
import conditionFn5 from './code/if/conditionFn-5.js';
import Icon from '../Icon';
import classNameFn5 from './code/icon/classNameFn-5.js';
import onClickFn from './code/box/onClickFn.js';
import conditionFn6 from './code/if/conditionFn-6.js';
import conditionFn7 from './code/if/conditionFn-7.js';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import childrenFn from './code/element/childrenFn.js';
import conditionFn8 from './code/if/conditionFn-8.js';
import conditionFn9 from './code/if/conditionFn-9.js';
import Button from '../Button';
import onClickFn1 from './code/button/onClickFn-1.js';
import styleFn from './code/box/styleFn.js';
import initialValueFn from './code/usestatestepopened/initialValueFn.js';
import keyFn from './code/defaultbox145/keyFn.js';
import styles from './styles.module.scss';

const ApplicationStatusPopup = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '102_356',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_102_266, props.className])}
      ref={ref}
      key={getKey(report, '102_266')}
    >
      {getKey(report, '111_89', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn, props, vars) ? (
        <Image
          src={b.tryCatch(srcFn, props, vars)}
          className={styles.clt_v200_103595}
          key={getKey(report, 'v200_103595')}
        />
      ) : null}
      <Box
        tag="div"
        className={styles.clt_102_308}
        key={getKey(report, '102_308')}
      >
        <Text
          text="Application progress for"
          tag="h3"
          className={styles.clt_102_271}
          key={getKey(report, '102_271')}
        />
        <Text
          text={b.tryCatch(textFn, props, vars)}
          tag="h3"
          className={styles.clt_102_305}
          key={getKey(report, '102_305')}
        />
      </Box>
      <Box
        tag="div"
        className={styles.clt_102_348}
        key={getKey(report, '102_348')}
      >
        {getKey(report, '102_349', null, '__CLUTCH_LOOP__') &&
          b.tryCatch(itemsFn, props, vars)?.map((item, index, items) =>
            (
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '102_349',
                  'children',
                  prevReport,
                  'defaultbox145',
                  args
                );

                return (
                  <Box
                    tag="div"
                    className={styles.clt_v200_103661}
                    key={getKey(
                      report,
                      'v200_103661',
                      b.tryCatch(keyFn, props, vars)
                    )}
                  >
                    <Box
                      tag="div"
                      className={styles.clt_105_0}
                      key={getKey(report, '105_0')}
                    >
                      {getKey(report, '105_86', null, '__CLUTCH_IF__') &&
                      b.tryCatch(conditionFn1, props, vars) ? (
                        <Box
                          className={b.cx([
                            styles.clt_v200_103524,
                            b.tryCatch(classNameFn, props, vars),
                          ])}
                          tag="div"
                          key={getKey(report, 'v200_103524')}
                        />
                      ) : null}
                      <Box
                        className={b.cx([
                          styles.clt_105_25,
                          b.tryCatch(classNameFn1, props, vars),
                        ])}
                        tag="div"
                        key={getKey(report, '105_25')}
                      />
                      {getKey(report, '105_112', null, '__CLUTCH_IF__') &&
                      b.tryCatch(conditionFn2, props, vars) ? (
                        <Box
                          className={b.cx([
                            styles.clt_v200_103547,
                            b.tryCatch(classNameFn2, props, vars),
                          ])}
                          tag="div"
                          key={getKey(report, 'v200_103547')}
                        />
                      ) : null}
                    </Box>
                    <Box
                      tag="div"
                      className={styles.clt_102_395}
                      key={getKey(report, '102_395')}
                    >
                      <UseState
                        initialValue={b.tryCatch(initialValueFn, props, vars)}
                        key={getKey(report, '102_623')}
                      >
                        {(
                          (prevReport) =>
                          (...args) => {
                            const [report, vars] = b.getReport(
                              '102_623',
                              'children',
                              prevReport,
                              'usestatestepopened',
                              args
                            );

                            return [
                              <Box
                                className={b.cx([
                                  styles.clt_102_396,
                                  b.tryCatch(classNameFn3, props, vars),
                                ])}
                                tag="div"
                                onClick={b.tryCatch(onClickFn, props, vars)}
                                key={getKey(report, '102_396')}
                              >
                                <Text
                                  className={b.cx([
                                    styles.clt_102_397,
                                    b.tryCatch(classNameFn4, props, vars),
                                  ])}
                                  text={b.tryCatch(textFn1, props, vars)}
                                  tag="span"
                                  key={getKey(report, '102_397')}
                                />
                                <Box
                                  tag="div"
                                  className={styles.clt_102_494}
                                  key={getKey(report, '102_494')}
                                >
                                  {getKey(
                                    report,
                                    '102_399',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn3, props, vars) ? (
                                    <Text
                                      text="Current"
                                      tag="span"
                                      className={styles.clt_v200_103410}
                                      key={getKey(report, 'v200_103410')}
                                    />
                                  ) : null}
                                  {getKey(
                                    report,
                                    '107_24',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn4, props, vars) ? (
                                    <Text
                                      text={b.tryCatch(textFn2, props, vars)}
                                      tag="span"
                                      className={styles.clt_v200_103570}
                                      key={getKey(report, 'v200_103570')}
                                    />
                                  ) : null}
                                  {getKey(
                                    report,
                                    '102_495',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn5, props, vars) ? (
                                    <Icon
                                      className={b.cx([
                                        styles.clt_v200_103433,
                                        b.tryCatch(classNameFn5, props, vars),
                                      ])}
                                      name="directional-down"
                                      key={getKey(report, 'v200_103433')}
                                      data-d={getKey(report, 'v200_103433')}
                                    />
                                  ) : null}
                                </Box>
                              </Box>,
                              getKey(
                                report,
                                '102_539',
                                null,
                                '__CLUTCH_IF__'
                              ) && b.tryCatch(conditionFn6, props, vars) ? (
                                <Box
                                  style={{
                                    display: b.tryCatch(styleFn, props, vars),
                                  }}
                                  tag="div"
                                  className={styles.clt_v200_103461}
                                  key={getKey(report, 'v200_103461')}
                                >
                                  {getKey(
                                    report,
                                    '102_593',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn7, props, vars) ? (
                                    <Element
                                      key={getKey(report, 'v200_103491')}
                                    >
                                      {b.tryCatch(childrenFn, props, vars)}
                                    </Element>
                                  ) : null}
                                  {getKey(
                                    report,
                                    '113_8',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn8, props, vars) ? (
                                    <Text
                                      text="You’ve already completed this step. No action is required for this step."
                                      tag="p"
                                      key={getKey(report, 'v200_103602')}
                                    />
                                  ) : null}
                                  {getKey(
                                    report,
                                    '102_594',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn9, props, vars) ? (
                                    <Button
                                      text={
                                        vars?.defaultbox145?.item?.actionName
                                      }
                                      loading={false}
                                      disabled={false}
                                      rounded
                                      size="medium"
                                      color="info"
                                      onClick={b.tryCatch(
                                        onClickFn1,
                                        props,
                                        vars
                                      )}
                                      key={getKey(report, 'v200_103509')}
                                      data-d={getKey(report, 'v200_103509')}
                                    />
                                  ) : null}
                                </Box>
                              ) : null,
                            ];
                          }
                        )(report)}
                      </UseState>
                    </Box>
                  </Box>
                );
              }
            )(report)?.({ item, index, items })
          )}
      </Box>
    </Box>
  );
});

ApplicationStatusPopup.displayName = 'ApplicationStatusPopup';

export default ApplicationStatusPopup;
