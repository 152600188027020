import { openInNewTab } from '@/src/utils/navigation';
import { isValidURL } from '@/src/utils/url';

const prevBindFn = (_, url) => {
  if (isValidURL(url)) openInNewTab(url);
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? (...args) =>
        prevBindFn(
          ...args,
          props?.['students'].find(
            ({ user_id }) =>
              user_id === vars?.['usestatepresentationpopupopened']?.['state']
          )?.finalPresentation.url
        )
    : prevBindFn;
