import { format } from 'date-fns';
import React from 'react';

import STEPS from './steps';

export const STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_FIT_AT_THIS_TIME: 'NOT_FIT_AT_THIS_TIME',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const STEP_STATUS = {
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
  LOCKED: 'LOCKED',
};

const Highlighted = ({ text }) => <span className="highlight">{text}</span>;

const getStepTitle = (step) => {
  switch (step) {
    case STEPS.APPLIED:
      return 'Written Application Submitted';
    case STEPS.VIDEO_OFFERED:
      return 'Video Offered';
    case STEPS.VIDEO_COMPLETED:
      return 'Video Completed (Review)';
    case STEPS.LIVE_OFFERED:
      return 'Live Interview Offer';
    case STEPS.LIVE_SCHEDULED:
      return 'Live Scheduled';
    case STEPS.LIVE_COMPLETED:
      return 'Live Completed (Review)';
    case STEPS.OFFER_SENT:
      return 'Offer Sent';
    case STEPS.ACCEPTED_OR_DECLINED:
      return 'Accepted/Declined';
    default:
      return '';
  }
};

export default class ApplicationStatus {
  constructor(data) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    this.all_steps = this.handlerSteps();
  }

  handlerSteps() {
    const allSteps = Object.values(STEPS).map((stepLabel) => {
      const lastStep = this.all_steps.find(({ stage }) => stage === stepLabel);
      return lastStep
        ? { ...lastStep, empty: false }
        : {
            stage: stepLabel,
            empty: true,
          };
    });

    const currentStepIndex = allSteps.findIndex(
      ({ stage_id }) => stage_id == this.current_step.stage_id
    );

    return allSteps.map((step, idx) => ({
      ...step,
      title: getStepTitle(step.stage),
      status:
        idx === currentStepIndex
          ? STEP_STATUS.ONGOING
          : idx > currentStepIndex
          ? STEP_STATUS.LOCKED
          : STEP_STATUS.COMPLETED,
      index: idx,
      actionName: step.action ? this.getActionNameByStepName(step.stage) : null,
    }));
  }

  get isPaused() {
    return this.status === STATUS.NOT_FIT_AT_THIS_TIME;
  }

  getActionNameByStepName(step) {
    switch (step) {
      case STEPS.APPLIED:
        return 'View Interview Process Page';
      case STEPS.VIDEO_OFFERED:
        return 'Record Video Responses';
      case STEPS.VIDEO_COMPLETED:
        return '';
      case STEPS.LIVE_OFFERED:
        return 'Book Live Interview';
      case STEPS.LIVE_SCHEDULED:
        return 'Required Action';
      case STEPS.LIVE_COMPLETED:
        return '';
      case STEPS.OFFER_SENT:
        return 'Review Offer Details';
      case STEPS.ACCEPTED_OR_DECLINED:
        return '';
      default:
        return '';
    }
  }

  getDescriptionComponent(step) {
    if (step.empty) return null;

    switch (step.stage) {
      case STEPS.APPLIED:
        return (
          <p>
            We&apos;re currently reviewing your application, you can expect to
            hear back within <Highlighted text={'three weeks'} />.
          </p>
        );
      case STEPS.VIDEO_OFFERED:
        return (
          <p>
            We&apos;ve sent you an email with some additional video questions on{' '}
            <Highlighted text={format(new Date(step.created_date), 'MM/dd')} />.
            If you haven&apos;t received this email, please check your junk
            mail. To move your application forward, please submit your video
            answers by{' '}
            <Highlighted text={format(new Date(step.limit_date), 'MM/dd')} />.
          </p>
        );
      case STEPS.VIDEO_COMPLETED:
        return (
          <p>
            We&apos;re currently reviewing your video answers. You can expect to
            hear back within 1 to 2 weeks.
          </p>
        );
      case STEPS.LIVE_OFFERED:
        return (
          <p>
            We&apos;ve sent you an email to schedule a live interview with you
            on{' '}
            <Highlighted text={format(new Date(step.created_date), 'MM/dd')} />.
            If you haven&apos;t seen this email, please check your junk mail. To
            move your application forward, please book an interview ASAP.
          </p>
        );
      case STEPS.LIVE_SCHEDULED:
        return (
          <p>
            Your live interview is scheduled for{' '}
            <Highlighted
              text={format(new Date(step.scheduled_date), 'MM/dd')}
            />
            , we&apos;re looking forward to hearing more from you! Meanwhile
            please refer to these live interview resources.
          </p>
        );
      case STEPS.LIVE_COMPLETED:
        return (
          <p>
            We&apos;re currently reviewing your application and you can expect
            to hear back from us with a final decision within{' '}
            <Highlighted text={'2-3'} /> weeks.
          </p>
        );
      case STEPS.OFFER_SENT:
        return (
          <p>
            We&apos;ve sent you an offer on{' '}
            <Highlighted text={format(new Date(step.created_date), 'MM/dd')} />.
            If you haven&apos;t seen this email, please check your junk mail. If
            you&apos;re still interested in this opportunity, please respond by{' '}
            <Highlighted text={format(new Date(step.limit_date), 'MM/dd')} />{' '}
            days.
          </p>
        );
      case STEPS.ACCEPTED_OR_DECLINED:
        return (
          <p>
            You&apos;ve{' '}
            <Highlighted
              text={
                this.status === STATUS.ACCEPTED
                  ? 'accept'
                  : this.status === STATUS.REJECTED
                  ? 'rejected'
                  : ''
              }
            />{' '}
            your offer for <Highlighted text={this.job_name} />.
          </p>
        );
      default:
        return null;
    }
  }
}
