import { cx } from '@clutch-creator/bridge';

export default (props, vars) =>
  cx(
    props?.['className'],
    props?.['rounded'] ? 'rounded' : '',
    props?.['color'],
    !!props?.['disabled'] && 'disabled',
    !!props?.['loading'] && 'loading'
  );
