export function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

export function downloadCsv(filename, content) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(content);
  hiddenElement.target = '_blank';

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = filename;
  hiddenElement.click();
}
