import { isPast } from 'date-fns';

const prevBindFn = (students) => {
  const count = students.filter(
    ({ end_date }) => !isPast(new Date(end_date))
  ).length;
  return `Current Externs (${count})`;
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['uselookbook2']?.['lookbook']?.['students'] || [])
    : prevBindFn;
