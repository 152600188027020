/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import conditionFn from './code/if/conditionFn.js';
import Element from '@clutch-marketplace/element/dist/cjs/components/Element';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import styles from './styles.module.scss';

const LabelFieldValue = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '13_157',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_13_165, props.className])}
      ref={ref}
      key={getKey(report, '13_165')}
    >
      {getKey(report, '14_60', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn, props, vars) ? (
        <Element key={getKey(report, 'v200_102694')}>{props?.Icon}</Element>
      ) : null}
      <Text
        text={props?.LabelText}
        tag="span"
        className={styles.clt_13_183}
        key={getKey(report, '13_183')}
      />
      <Text
        text={props?.ValueText}
        tag="span"
        className={styles.clt_13_184}
        key={getKey(report, '13_184')}
      />
    </Box>
  );
});

LabelFieldValue.displayName = 'LabelFieldValue';

export default LabelFieldValue;
