import {
  extractVideoIDBySource,
  extractVideoSource,
  isValidURL,
} from '@/src/utils/url';

export default class StudentInfo {
  constructor(data) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    this.id = data.user_id || '';
    this.fullName = data.full_name || '';
    this.linkedinUrl = data.linkedin_username
      ? `https://www.linkedin.com/in/${data.linkedin_username}`
      : null;

    this.finalPresentation = {
      url: isValidURL(data.final_output_answer)
        ? data.final_output_answer
        : null,
    };
    this.finalPresentation.source = extractVideoSource(
      this.finalPresentation.url
    );
    this.finalPresentation.id = extractVideoIDBySource(
      this.finalPresentation.url,
      this.finalPresentation.source
    );
  }
}
