const prevBindFn = (isSubmitSuccessful, reset, closePopup) => {
  if (isSubmitSuccessful) {
    reset();
    closePopup();
  }
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? (...args) =>
        prevBindFn(
          ...args,
          vars?.['useform1']?.['formState'].isSubmitSuccessful,
          vars?.['useform1']?.['reset'],
          () => vars?.['usestatedownloadpopupopened']?.['setState'](false)
        )
    : prevBindFn;
