import useSWR from 'swr';
import StudentInfo from '../../domain/StudentInfo';

export default ({ children, code, options = {} }) => {
  const { data, error, mutate, isValidating } = useSWR(
    code ? `/v2/lookbooks/${code}` : null,
    options
  );

  let lookbook = null;

  if (!error && data) {
    lookbook = {
      ...data,
      students: data.students.map((si) => new StudentInfo(si)),
    };
  }

  return children
    ? typeof children === 'function'
      ? children({
          lookbook,
          isLoading: !error && !data,
          isError: error,
          mutate,
          isValidating,
        })
      : children
    : null;
};
