/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import HelmetTitle from '@clutch-marketplace/helmet/dist/cjs/components/HelmetTitle';
import UseLookbook from '../UseLookbook';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import IfElse from '@clutch-marketplace/logic/dist/cjs/components/IfElse';
import conditionFn from './code/if/conditionFn.js';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import PoweredByParagonOne from '../PoweredByParagonOne';
import conditionFn1 from './code/if/conditionFn-1.js';
import Image from '@clutch-marketplace/web-elements/dist/cjs/components/Image';
import styleFn from './code/image/styleFn.js';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import HTMLElement from '../HTMLElement';
import innerHTMLFn from './code/hTMLElement/innerHTMLFn.js';
import LabelFieldValue from '../LabelFieldValue';
import ValueTextFn from './code/labelFieldValue/ValueTextFn.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import ValueTextFn1 from './code/labelFieldValue/ValueTextFn-1.js';
import conditionFn4 from './code/if/conditionFn-4.js';
import Button from '../Button';
import onClickFn from './code/button/onClickFn.js';
import Popup from '../Popup';
import UseForm from '@clutch-marketplace/paragonone.react-hook-form/dist/cjs/components/UseForm';
import UseEffect from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseEffect';
import TextField from '../TextField';
import registerFn from './code/textField/registerFn.js';
import registerFn1 from './code/textField/registerFn-1.js';
import registerFn2 from './code/textField/registerFn-2.js';
import registerFn3 from './code/textField/registerFn-3.js';
import loadingFn from './code/button/loadingFn.js';
import disabledFn from './code/button/disabledFn.js';
import onClickFn1 from './code/button/onClickFn-1.js';
import callbackFn from './code/useEffect/callbackFn.js';
import dependenciesFn from './code/useEffect/dependenciesFn.js';
import defaultValuesFn from './code/useform1/defaultValuesFn.js';
import defaultValuesFn1 from './code/useform1/defaultValuesFn-1.js';
import defaultValuesFn2 from './code/useform1/defaultValuesFn-2.js';
import defaultValuesFn3 from './code/useform1/defaultValuesFn-3.js';
import onCloseFn from './code/popupgetspreadsheet/onCloseFn.js';
import conditionFn5 from './code/if/conditionFn-5.js';
import textFn1 from './code/text/textFn-1.js';
import conditionFn6 from './code/if/conditionFn-6.js';
import textFn2 from './code/text/textFn-2.js';
import NavigationTabs from '../NavigationTabs';
import itemsFn from './code/navigationTabs/itemsFn.js';
import itemsFn1 from './code/navigationTabs/itemsFn-1.js';
import itemsFn2 from './code/navigationTabs/itemsFn-2.js';
import itemsFn3 from './code/navigationTabs/itemsFn-3.js';
import activeIndexFn from './code/navigationTabs/activeIndexFn.js';
import StudentsList from '../StudentsList';
import studentsFn from './code/studentsList/studentsFn.js';
import showPresentationsFn from './code/studentsList/showPresentationsFn.js';
import Loading from '../Loading';
import conditionFn7 from './code/ifElse/conditionFn-7.js';
import initialValueFn from './code/usestateactivetab/initialValueFn.js';
import styles from './styles.module.scss';

const LookbookPage = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '20_52',
    propsArg,
    vars,
    variants
  );

  return (
    <>
      <HelmetTitle
        title="Student Lookbook"
        className={props.className}
        ref={ref}
        key={getKey(report, '24_298')}
      />
      <UseLookbook code={props?.code} key={getKey(report, '15_0')}>
        {(
          (prevReport) =>
          (...args) => {
            const [report, vars] = b.getReport(
              '15_0',
              'children',
              prevReport,
              'uselookbook2',
              args
            );

            return (
              <UseState
                initialValue={b.tryCatch(initialValueFn, props, vars)}
                key={getKey(report, '48_41')}
              >
                {(
                  (prevReport) =>
                  (...args) => {
                    const [report, vars] = b.getReport(
                      '48_41',
                      'children',
                      prevReport,
                      'usestateactivetab',
                      args
                    );

                    return (
                      <IfElse
                        if={
                          getKey(
                            report,
                            '17eb1f60fcefae252001b03a',
                            null,
                            '__CLUTCH_IF__'
                          ) && b.tryCatch(conditionFn, props, vars) ? (
                            <Box
                              tag="div"
                              className={styles.clt_v200_102674}
                              key={getKey(report, 'v200_102674')}
                            >
                              <PoweredByParagonOne
                                key={getKey(report, '13_94')}
                                data-d={getKey(report, '13_94')}
                              />
                              <Box
                                tag="div"
                                className={styles.clt_24_2}
                                key={getKey(report, '24_2')}
                              >
                                <Box
                                  tag="div"
                                  className={styles.clt_24_16}
                                  key={getKey(report, '24_16')}
                                >
                                  <Box
                                    tag="div"
                                    className={styles.clt_24_167}
                                    key={getKey(report, '24_167')}
                                  >
                                    <Box
                                      tag="div"
                                      className={styles.clt_24_69}
                                      key={getKey(report, '24_69')}
                                    >
                                      <Box
                                        tag="div"
                                        className={styles.clt_24_72}
                                        key={getKey(report, '24_72')}
                                      >
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_42}
                                          key={getKey(report, '24_42')}
                                        />
                                      </Box>
                                      <Box
                                        tag="div"
                                        className={styles.clt_24_73}
                                        key={getKey(report, '24_73')}
                                      >
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_71}
                                          key={getKey(report, '24_71')}
                                        />
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_70}
                                          key={getKey(report, '24_70')}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    tag="div"
                                    className={styles.clt_24_145}
                                    key={getKey(report, '24_145')}
                                  />
                                  <Box
                                    tag="div"
                                    className={styles.clt_24_181}
                                    key={getKey(report, '24_181')}
                                  >
                                    <Box
                                      tag="div"
                                      className={styles.clt_24_138}
                                      key={getKey(report, '24_138')}
                                    >
                                      <Box
                                        tag="div"
                                        className={styles.clt_24_139}
                                        key={getKey(report, '24_139')}
                                      >
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_140}
                                          key={getKey(report, '24_140')}
                                        />
                                      </Box>
                                      <Box
                                        tag="div"
                                        className={styles.clt_24_141}
                                        key={getKey(report, '24_141')}
                                      >
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_142}
                                          key={getKey(report, '24_142')}
                                        />
                                        <Box
                                          tag="div"
                                          className={styles.clt_24_143}
                                          key={getKey(report, '24_143')}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  tag="div"
                                  className={styles.clt_13_78}
                                  key={getKey(report, '13_78')}
                                >
                                  {getKey(
                                    report,
                                    '15_113',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn1, props, vars) ? (
                                    <Image
                                      src={
                                        vars?.uselookbook2?.lookbook?.about
                                          ?.company_logo
                                      }
                                      style={{
                                        maxWidth: b.tryCatch(
                                          styleFn,
                                          props,
                                          vars
                                        ),
                                      }}
                                      className={styles.clt_v200_102732}
                                      key={getKey(report, 'v200_102732')}
                                    />
                                  ) : null}
                                  <Text
                                    text={b.tryCatch(textFn, props, vars)}
                                    tag="h1"
                                    className={styles.clt_13_95}
                                    key={getKey(report, '13_95')}
                                  />
                                  {getKey(
                                    report,
                                    '17_275',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn2, props, vars) ? (
                                    <HTMLElement
                                      tag="div"
                                      innerHTML={b.tryCatch(
                                        innerHTMLFn,
                                        props,
                                        vars
                                      )}
                                      className={styles.clt_v200_102741}
                                      key={getKey(report, 'v200_102741')}
                                      data-d={getKey(report, 'v200_102741')}
                                    />
                                  ) : null}
                                  <Box
                                    tag="div"
                                    className={styles.clt_14_65}
                                    key={getKey(report, '14_65')}
                                  >
                                    <LabelFieldValue
                                      LabelText="Participants:"
                                      ValueText={b.tryCatch(
                                        ValueTextFn,
                                        props,
                                        vars
                                      )}
                                      Icon={
                                        <HTMLElement
                                          tag="div"
                                          innerHTML='<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.7679 14.9141C16.3994 14.0414 15.8648 13.2486 15.1937 12.5801C14.5246 11.9096 13.732 11.375 12.8597 11.0059C12.8519 11.002 12.844 11 12.8362 10.9961C14.053 10.1172 14.844 8.68555 14.844 7.07031C14.844 4.39453 12.6761 2.22656 10.0003 2.22656C7.32451 2.22656 5.15654 4.39453 5.15654 7.07031C5.15654 8.68555 5.94756 10.1172 7.16436 10.998C7.15654 11.002 7.14873 11.0039 7.14092 11.0078C6.26592 11.377 5.48076 11.9063 4.80693 12.582C4.13644 13.2511 3.60186 14.0437 3.23271 14.916C2.87006 15.77 2.67448 16.6856 2.65654 17.6133C2.65602 17.6341 2.65968 17.6549 2.6673 17.6743C2.67492 17.6937 2.68634 17.7114 2.7009 17.7263C2.71546 17.7412 2.73287 17.7531 2.75208 17.7612C2.7713 17.7693 2.79194 17.7734 2.81279 17.7734H3.98467C4.07061 17.7734 4.13896 17.7051 4.14092 17.6211C4.17998 16.1133 4.78545 14.7012 5.85576 13.6309C6.96318 12.5234 8.43389 11.9141 10.0003 11.9141C11.5667 11.9141 13.0374 12.5234 14.1448 13.6309C15.2151 14.7012 15.8206 16.1133 15.8597 17.6211C15.8616 17.707 15.93 17.7734 16.0159 17.7734H17.1878C17.2086 17.7734 17.2293 17.7693 17.2485 17.7612C17.2677 17.7531 17.2851 17.7412 17.2997 17.7263C17.3142 17.7114 17.3257 17.6937 17.3333 17.6743C17.3409 17.6549 17.3446 17.6341 17.344 17.6133C17.3245 16.6797 17.1312 15.7715 16.7679 14.9141V14.9141ZM10.0003 10.4297C9.10381 10.4297 8.26006 10.0801 7.62529 9.44531C6.99053 8.81055 6.64092 7.9668 6.64092 7.07031C6.64092 6.17383 6.99053 5.33008 7.62529 4.69531C8.26006 4.06055 9.10381 3.71094 10.0003 3.71094C10.8968 3.71094 11.7405 4.06055 12.3753 4.69531C13.0101 5.33008 13.3597 6.17383 13.3597 7.07031C13.3597 7.9668 13.0101 8.81055 12.3753 9.44531C11.7405 10.0801 10.8968 10.4297 10.0003 10.4297Z" fill="#061237"/> </svg>'
                                          className={styles.clt_17_270}
                                          key={getKey(report, '17_270')}
                                          data-d={getKey(report, '17_270')}
                                        />
                                      }
                                      key={getKey(report, '14_66')}
                                      data-d={getKey(report, '14_66')}
                                    />
                                    {getKey(
                                      report,
                                      '14_68',
                                      null,
                                      '__CLUTCH_IF__'
                                    ) &&
                                    b.tryCatch(conditionFn3, props, vars) ? (
                                      <LabelFieldValue
                                        LabelText="Duration:"
                                        ValueText={b.tryCatch(
                                          ValueTextFn1,
                                          props,
                                          vars
                                        )}
                                        Icon={
                                          <HTMLElement
                                            tag="div"
                                            innerHTML='<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.1875 3.59375H13.9062V2.34375C13.9062 2.25781 13.8359 2.1875 13.75 2.1875H12.6562C12.5703 2.1875 12.5 2.25781 12.5 2.34375V3.59375H7.5V2.34375C7.5 2.25781 7.42969 2.1875 7.34375 2.1875H6.25C6.16406 2.1875 6.09375 2.25781 6.09375 2.34375V3.59375H2.8125C2.4668 3.59375 2.1875 3.87305 2.1875 4.21875V17.1875C2.1875 17.5332 2.4668 17.8125 2.8125 17.8125H17.1875C17.5332 17.8125 17.8125 17.5332 17.8125 17.1875V4.21875C17.8125 3.87305 17.5332 3.59375 17.1875 3.59375ZM16.4062 16.4062H3.59375V8.98438H16.4062V16.4062ZM3.59375 7.65625V5H6.09375V5.9375C6.09375 6.02344 6.16406 6.09375 6.25 6.09375H7.34375C7.42969 6.09375 7.5 6.02344 7.5 5.9375V5H12.5V5.9375C12.5 6.02344 12.5703 6.09375 12.6562 6.09375H13.75C13.8359 6.09375 13.9062 6.02344 13.9062 5.9375V5H16.4062V7.65625H3.59375Z" fill="#061237"/> </svg>'
                                            className={styles.clt_17_273}
                                            key={getKey(report, '17_273')}
                                            data-d={getKey(report, '17_273')}
                                          />
                                        }
                                        key={getKey(report, 'v200_102712')}
                                        data-d={getKey(report, 'v200_102712')}
                                      />
                                    ) : null}
                                    {getKey(
                                      report,
                                      '28_436',
                                      null,
                                      '__CLUTCH_IF__'
                                    ) &&
                                    b.tryCatch(conditionFn4, props, vars) ? (
                                      <UseState
                                        initialValue={false}
                                        key={getKey(report, 'v200_102833')}
                                      >
                                        {(
                                          (prevReport) =>
                                          (...args) => {
                                            const [report, vars] = b.getReport(
                                              'v200_102833',
                                              'children',
                                              prevReport,
                                              'usestatedownloadpopupopened',
                                              args
                                            );

                                            return [
                                              <Button
                                                text="Access Spreadsheet"
                                                loading={false}
                                                disabled={false}
                                                rounded
                                                size="medium"
                                                color="info"
                                                onClick={b.tryCatch(
                                                  onClickFn,
                                                  props,
                                                  vars
                                                )}
                                                key={getKey(report, '26_100')}
                                                data-d={getKey(
                                                  report,
                                                  '26_100'
                                                )}
                                              />,
                                              <Popup
                                                open={
                                                  vars
                                                    ?.usestatedownloadpopupopened
                                                    ?.state
                                                }
                                                onClose={b.tryCatch(
                                                  onCloseFn,
                                                  props,
                                                  vars
                                                )}
                                                hideCloseButton={false}
                                                wrapperClassName="genClass42"
                                                className={styles.clt_28_437}
                                                key={getKey(report, '28_437')}
                                                data-d={getKey(
                                                  report,
                                                  '28_437'
                                                )}
                                              >
                                                {(
                                                  (prevReport) =>
                                                  (...args) => {
                                                    const [report, vars] =
                                                      b.getReport(
                                                        '28_437',
                                                        'children',
                                                        prevReport,
                                                        'popupgetspreadsheet',
                                                        args
                                                      );

                                                    return (
                                                      <Box
                                                        tag="div"
                                                        className={
                                                          styles.clt_28_452
                                                        }
                                                        key={getKey(
                                                          report,
                                                          '28_452'
                                                        )}
                                                      >
                                                        <Text
                                                          text="Outstanding Students Spreadsheet"
                                                          tag="h3"
                                                          className={
                                                            styles.clt_28_453
                                                          }
                                                          key={getKey(
                                                            report,
                                                            '28_453'
                                                          )}
                                                        />
                                                        <Text
                                                          text="Please complete this form to gain access."
                                                          tag="p"
                                                          className={
                                                            styles.clt_28_471
                                                          }
                                                          key={getKey(
                                                            report,
                                                            '28_471'
                                                          )}
                                                        />
                                                        <UseForm
                                                          mode="all"
                                                          defaultValues={{
                                                            yourTitle:
                                                              b.tryCatch(
                                                                defaultValuesFn,
                                                                props,
                                                                vars
                                                              ),
                                                            companyName:
                                                              b.tryCatch(
                                                                defaultValuesFn1,
                                                                props,
                                                                vars
                                                              ),
                                                            workEmail:
                                                              b.tryCatch(
                                                                defaultValuesFn2,
                                                                props,
                                                                vars
                                                              ),
                                                            firstName:
                                                              b.tryCatch(
                                                                defaultValuesFn3,
                                                                props,
                                                                vars
                                                              ),
                                                          }}
                                                          key={getKey(
                                                            report,
                                                            '28_507'
                                                          )}
                                                        >
                                                          {(
                                                            (prevReport) =>
                                                            (...args) => {
                                                              const [
                                                                report,
                                                                vars,
                                                              ] = b.getReport(
                                                                '28_507',
                                                                'children',
                                                                prevReport,
                                                                'useform1',
                                                                args
                                                              );

                                                              return (
                                                                <UseEffect
                                                                  callback={b.tryCatch(
                                                                    callbackFn,
                                                                    props,
                                                                    vars
                                                                  )}
                                                                  dependencies={[
                                                                    b.tryCatch(
                                                                      dependenciesFn,
                                                                      props,
                                                                      vars
                                                                    ),
                                                                    vars
                                                                      ?.useform1
                                                                      ?.reset,
                                                                  ]}
                                                                  key={getKey(
                                                                    report,
                                                                    '29_98'
                                                                  )}
                                                                  data-d={getKey(
                                                                    report,
                                                                    '29_98'
                                                                  )}
                                                                >
                                                                  {(
                                                                    (
                                                                      prevReport
                                                                    ) =>
                                                                    (
                                                                      ...args
                                                                    ) => {
                                                                      const [
                                                                        report,
                                                                        vars,
                                                                      ] =
                                                                        b.getReport(
                                                                          '29_98',
                                                                          'children',
                                                                          prevReport,
                                                                          'useEffect',
                                                                          args
                                                                        );

                                                                      return [
                                                                        <TextField
                                                                          placeholder="First Name"
                                                                          type="text"
                                                                          register={b.tryCatch(
                                                                            registerFn,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          required
                                                                          key={getKey(
                                                                            report,
                                                                            '28_503'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            '28_503'
                                                                          )}
                                                                        />,
                                                                        <TextField
                                                                          placeholder="Work Email"
                                                                          type="email"
                                                                          register={b.tryCatch(
                                                                            registerFn1,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          required
                                                                          key={getKey(
                                                                            report,
                                                                            '29_2'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            '29_2'
                                                                          )}
                                                                        />,
                                                                        <TextField
                                                                          placeholder="Company Name"
                                                                          type="text"
                                                                          register={b.tryCatch(
                                                                            registerFn2,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          required
                                                                          key={getKey(
                                                                            report,
                                                                            '29_1'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            '29_1'
                                                                          )}
                                                                        />,
                                                                        <TextField
                                                                          placeholder="Your title"
                                                                          type="text"
                                                                          register={b.tryCatch(
                                                                            registerFn3,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          required
                                                                          key={getKey(
                                                                            report,
                                                                            '29_0'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            '29_0'
                                                                          )}
                                                                        />,
                                                                        <Button
                                                                          text="Submit"
                                                                          loading={b.tryCatch(
                                                                            loadingFn,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          disabled={b.tryCatch(
                                                                            disabledFn,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          rounded
                                                                          size="medium"
                                                                          color="info"
                                                                          onClick={b.tryCatch(
                                                                            onClickFn1,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          key={getKey(
                                                                            report,
                                                                            '29_6'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            '29_6'
                                                                          )}
                                                                        />,
                                                                      ];
                                                                    }
                                                                  )(report)}
                                                                </UseEffect>
                                                              );
                                                            }
                                                          )(report)}
                                                        </UseForm>
                                                      </Box>
                                                    );
                                                  }
                                                )(report)}
                                              </Popup>,
                                            ];
                                          }
                                        )(report)}
                                      </UseState>
                                    ) : null}
                                  </Box>
                                  <Box
                                    tag="div"
                                    className={styles.clt_48_93}
                                    key={getKey(report, '48_93')}
                                  >
                                    {getKey(
                                      report,
                                      '14_95',
                                      null,
                                      '__CLUTCH_IF__'
                                    ) &&
                                    b.tryCatch(conditionFn5, props, vars) ? (
                                      <Text
                                        text={b.tryCatch(textFn1, props, vars)}
                                        tag="h2"
                                        className={styles.clt_v200_102717}
                                        key={getKey(report, 'v200_102717')}
                                      />
                                    ) : null}
                                    {getKey(
                                      report,
                                      '48_35',
                                      null,
                                      '__CLUTCH_IF__'
                                    ) &&
                                    b.tryCatch(conditionFn6, props, vars) ? (
                                      <Box
                                        tag="div"
                                        className={styles.clt_v200_102868}
                                        key={getKey(report, 'v200_102868')}
                                      >
                                        <Text
                                          text={b.tryCatch(
                                            textFn2,
                                            props,
                                            vars
                                          )}
                                          tag="h2"
                                          className={styles.clt_48_36}
                                          key={getKey(report, '48_36')}
                                        />
                                        <NavigationTabs
                                          items={[
                                            {
                                              displayName: b.tryCatch(
                                                itemsFn,
                                                props,
                                                vars
                                              ),
                                              onClick: b.tryCatch(
                                                itemsFn1,
                                                props,
                                                vars
                                              ),
                                            },
                                            {
                                              displayName: b.tryCatch(
                                                itemsFn2,
                                                props,
                                                vars
                                              ),
                                              onClick: b.tryCatch(
                                                itemsFn3,
                                                props,
                                                vars
                                              ),
                                            },
                                          ]}
                                          activeIndex={b.tryCatch(
                                            activeIndexFn,
                                            props,
                                            vars
                                          )}
                                          key={getKey(report, '48_189')}
                                          data-d={getKey(report, '48_189')}
                                        />
                                      </Box>
                                    ) : null}
                                  </Box>
                                </Box>
                                <Box
                                  tag="div"
                                  className={styles.clt_14_94}
                                  key={getKey(report, '14_94')}
                                >
                                  <StudentsList
                                    students={b.tryCatch(
                                      studentsFn,
                                      props,
                                      vars
                                    )}
                                    showPresentations={b.tryCatch(
                                      showPresentationsFn,
                                      props,
                                      vars
                                    )}
                                    key={getKey(report, '14_267')}
                                    data-d={getKey(report, '14_267')}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          ) : null
                        }
                        else={
                          <Box
                            tag="div"
                            className={styles.clt_17_192}
                            key={getKey(report, '17_192')}
                          >
                            <Loading
                              key={getKey(report, '17_193')}
                              data-d={getKey(report, '17_193')}
                            />
                          </Box>
                        }
                        condition={b.tryCatch(conditionFn7, props, vars)}
                        key={getKey(report, '15_16')}
                      />
                    );
                  }
                )(report)}
              </UseState>
            );
          }
        )(report)}
      </UseLookbook>
    </>
  );
});

LookbookPage.displayName = 'LookbookPage';

export default LookbookPage;
