/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import HelmetScript from '@clutch-marketplace/helmet/dist/cjs/components/HelmetScript';
import conditionFn from './code/if/conditionFn.js';
import BrowserRouter from '@clutch-marketplace/react-router/dist/cjs/components/BrowserRouter';
import Switch from '@clutch-marketplace/react-router/dist/cjs/components/Switch';
import Route from '@clutch-marketplace/react-router/dist/cjs/components/Route';
import LookbookPage from '../LookbookPage';
import UseParams from '@clutch-marketplace/react-router/dist/cjs/components/UseParams';
import _404 from '../404';
import UseEffect from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseEffect';
import HelmetMeta from '@clutch-marketplace/helmet/dist/cjs/components/HelmetMeta';
import contentFn from './code/helmetMeta/contentFn.js';
import contentFn1 from './code/helmetMeta/contentFn-1.js';
import contentFn2 from './code/helmetMeta/contentFn-2.js';
import contentFn3 from './code/helmetMeta/contentFn-3.js';
import callbackFn from './code/useEffect/callbackFn.js';
import OfferLetterPage from '../OfferLetterPage';
import UseApplicationsStatus from '../UseApplicationsStatus';
import conditionFn1 from './code/if/conditionFn-1.js';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import PoweredByParagonOne from '../PoweredByParagonOne';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import Popup from '../Popup';
import ApplicationStatusPopup from '../ApplicationStatusPopup';
import applicationStatusFn from './code/applicationStatusPopup/applicationStatusFn.js';
import openFn from './code/popup/openFn.js';
import onCloseFn from './code/popup/onCloseFn.js';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import itemsFn from './code/applicationcard2/itemsFn.js';
import ApplicationCard from '../ApplicationCard';
import titleFn from './code/applicationcard2/titleFn.js';
import idFn from './code/applicationcard2/idFn.js';
import stepFn from './code/applicationcard2/stepFn.js';
import conditionFn2 from './code/if/conditionFn-2.js';
import Button from '../Button';
import textFn from './code/button/textFn.js';
import onClickFn from './code/button/onClickFn.js';
import statusFn from './code/applicationcard2/statusFn.js';
import keyFn from './code/applicationcard2/keyFn.js';
import bodyFn from './code/applicationcard2/bodyFn.js';
import companyLogoFn from './code/applicationcard2/companyLogoFn.js';
import conditionFn3 from './code/if/conditionFn-3.js';
import itemsFn1 from './code/applicationcard5/itemsFn-1.js';
import stepFn1 from './code/applicationCard/stepFn-1.js';
import bodyFn1 from './code/applicationCard/bodyFn-1.js';
import Loading from '../Loading';
import conditionFn4 from './code/if/conditionFn-4.js';
import PortfolioPage from '../PortfolioPage';
import styles from './styles.module.scss';

const PublicApp = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '20_110',
    propsArg,
    vars,
    variants
  );

  return (
    <>
      {getKey(report, '175_93', null, '__CLUTCH_IF__') &&
      b.tryCatch(conditionFn, props, vars) ? (
        <HelmetScript
          script={
            "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MPK3RR7');"
          }
          className={props.className}
          ref={ref}
          key={getKey(report, '175_16')}
        />
      ) : null}
      <BrowserRouter key={getKey(report, '20_79')}>
        <Switch key={getKey(report, '69_5')}>
          <Route
            path="/lookbook"
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '69_1',
                  'render',
                  prevReport,
                  'routelookbook',
                  args
                );

                return (
                  <Switch key={getKey(report, '20_81')}>
                    <Route
                      path="/lookbook/outstanding"
                      exact
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '20_101',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <LookbookPage
                              code="outstanding"
                              isOutstanding
                              key={getKey(report, '29_119')}
                              data-d={getKey(report, '29_119')}
                            />
                          );
                        }
                      )(report)}
                      key={getKey(report, '20_101')}
                    />
                    <Route
                      path="/lookbook/successful-outstanding-female-non-binary"
                      exact
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '168_428',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <LookbookPage
                              code="successful-outstanding-female-non-binary"
                              isOutstanding
                              key={getKey(report, '168_429')}
                              data-d={getKey(report, '168_429')}
                            />
                          );
                        }
                      )(report)}
                      key={getKey(report, '168_428')}
                    />
                    <Route
                      path="/lookbook/national-geographic-and-the-nature-outstanding"
                      exact
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '43_0',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <LookbookPage
                              code="national-geographic-and-the-nature-outstanding"
                              isOutstanding
                              showLogo
                              key={getKey(report, '43_1')}
                              data-d={getKey(report, '43_1')}
                            />
                          );
                        }
                      )(report)}
                      key={getKey(report, '43_0')}
                    />
                    <Route
                      path="/lookbook/consulting-finance-focus-outstanding"
                      exact
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '86_0',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <LookbookPage
                              code="finance-outstanding"
                              isOutstanding
                              showLogo
                              key={getKey(report, '86_1')}
                              data-d={getKey(report, '86_1')}
                            />
                          );
                        }
                      )(report)}
                      key={getKey(report, '86_0')}
                    />
                    <Route
                      path="/lookbook/:lookbook_code"
                      exact
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '20_82',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <UseParams
                              key={getKey(report, '20_98')}
                              data-d={getKey(report, '20_98')}
                            >
                              {(
                                (prevReport) =>
                                (...args) => {
                                  const [report, vars] = b.getReport(
                                    '20_98',
                                    'children',
                                    prevReport,
                                    'defaultUseParams1',
                                    args
                                  );

                                  return (
                                    <LookbookPage
                                      code={
                                        vars?.defaultUseParams1?.lookbook_code
                                      }
                                      key={getKey(report, '20_77')}
                                      data-d={getKey(report, '20_77')}
                                    />
                                  );
                                }
                              )(report)}
                            </UseParams>
                          );
                        }
                      )(report)}
                      key={getKey(report, '20_82')}
                    />
                    <Route
                      render={(
                        (prevReport) =>
                        (...args) => {
                          const [report, vars] = b.getReport(
                            '96_281',
                            'render',
                            prevReport,
                            'route',
                            args
                          );

                          return (
                            <_404
                              key={getKey(report, '96_282')}
                              data-d={getKey(report, '96_282')}
                            />
                          );
                        }
                      )(report)}
                      key={getKey(report, '96_281')}
                    />
                  </Switch>
                );
              }
            )(report)}
            key={getKey(report, '69_1')}
          />
          <Route
            path="/offer/acceptance-image/:image"
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '96_283',
                  'render',
                  prevReport,
                  'routeofferacceptanceimage',
                  args
                );

                return (
                  <UseParams
                    key={getKey(report, '96_349')}
                    data-d={getKey(report, '96_349')}
                  >
                    {(
                      (prevReport) =>
                      (...args) => {
                        const [report, vars] = b.getReport(
                          '96_349',
                          'children',
                          prevReport,
                          'defaultUseParams3',
                          args
                        );

                        return (
                          <UseEffect
                            callback={b.tryCatch(callbackFn, props, vars)}
                            key={getKey(report, '96_328')}
                            data-d={getKey(report, '96_328')}
                          >
                            {(
                              (prevReport) =>
                              (...args) => {
                                const [report, vars] = b.getReport(
                                  '96_328',
                                  'children',
                                  prevReport,
                                  'useEffect',
                                  args
                                );

                                return [
                                  <HelmetMeta
                                    property="og:title"
                                    content="Remote Externships - Kickstart Your Career"
                                    key={getKey(report, '96_289')}
                                  />,
                                  <HelmetMeta
                                    property="og:type"
                                    content="website"
                                    key={getKey(report, '96_298')}
                                  />,
                                  <HelmetMeta
                                    property="og:description"
                                    content=""
                                    key={getKey(report, '97_360')}
                                  />,
                                  <HelmetMeta
                                    property="og:site_name"
                                    content="Remote Externships - Kickstart Your Career"
                                    key={getKey(report, '96_316')}
                                  />,
                                  <HelmetMeta
                                    property="og:url"
                                    content={b.tryCatch(contentFn, props, vars)}
                                    key={getKey(report, '96_304')}
                                  />,
                                  <HelmetMeta
                                    property="og:image"
                                    content={b.tryCatch(
                                      contentFn1,
                                      props,
                                      vars
                                    )}
                                    key={getKey(report, '96_301')}
                                  />,
                                  <HelmetMeta
                                    property="og:image:secure_url"
                                    content={b.tryCatch(
                                      contentFn2,
                                      props,
                                      vars
                                    )}
                                    key={getKey(report, '96_341')}
                                  />,
                                  <HelmetMeta
                                    property="og:image:alt"
                                    content="Offer Accepted"
                                    key={getKey(report, '97_371')}
                                  />,
                                  <HelmetMeta
                                    name="twitter:card"
                                    content="summary_large_image"
                                    key={getKey(report, '96_308')}
                                  />,
                                  <HelmetMeta
                                    name="twitter:image"
                                    content={b.tryCatch(
                                      contentFn3,
                                      props,
                                      vars
                                    )}
                                    key={getKey(report, '97_363')}
                                  />,
                                  <HelmetMeta
                                    name="twitter:image:alt"
                                    content="Offer Accepted"
                                    key={getKey(report, '96_319')}
                                  />,
                                ];
                              }
                            )(report)}
                          </UseEffect>
                        );
                      }
                    )(report)}
                  </UseParams>
                );
              }
            )(report)}
            key={getKey(report, '96_283')}
          />
          <Route
            path="/offer/:loginString/:applicationID"
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '69_6',
                  'render',
                  prevReport,
                  'routeoffer',
                  args
                );

                return (
                  <OfferLetterPage
                    key={getKey(report, '69_21')}
                    data-d={getKey(report, '69_21')}
                  />
                );
              }
            )(report)}
            key={getKey(report, '69_6')}
          />
          <Route
            path="/applications/status/:loginString"
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '98_4',
                  'render',
                  prevReport,
                  'routeapplicationstatus',
                  args
                );

                return (
                  <UseParams
                    key={getKey(report, '102_234')}
                    data-d={getKey(report, '102_234')}
                  >
                    {(
                      (prevReport) =>
                      (...args) => {
                        const [report, vars] = b.getReport(
                          '102_234',
                          'children',
                          prevReport,
                          'defaultUseParams4',
                          args
                        );

                        return (
                          <UseApplicationsStatus
                            loginString={vars?.defaultUseParams4?.loginString}
                            options={{
                              revalidateOnFocus: true,
                            }}
                            key={getKey(report, '101_378')}
                          >
                            {(
                              (prevReport) =>
                              (...args) => {
                                const [report, vars] = b.getReport(
                                  '101_378',
                                  'children',
                                  prevReport,
                                  'useapplicationsstatus2',
                                  args
                                );

                                return [
                                  getKey(
                                    report,
                                    '98_16',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn1, props, vars) ? (
                                    <Box
                                      tag="div"
                                      className={styles.clt_v200_103353}
                                      key={getKey(report, 'v200_103353')}
                                    >
                                      <PoweredByParagonOne
                                        key={getKey(report, '98_17')}
                                        data-d={getKey(report, '98_17')}
                                      />
                                      <UseState key={getKey(report, '102_239')}>
                                        {(
                                          (prevReport) =>
                                          (...args) => {
                                            const [report, vars] = b.getReport(
                                              '102_239',
                                              'children',
                                              prevReport,
                                              'usestateappdetailspopup',
                                              args
                                            );

                                            return [
                                              <Popup
                                                open={b.tryCatch(
                                                  openFn,
                                                  props,
                                                  vars
                                                )}
                                                onClose={b.tryCatch(
                                                  onCloseFn,
                                                  props,
                                                  vars
                                                )}
                                                hideCloseButton={false}
                                                key={getKey(report, '102_251')}
                                                data-d={getKey(
                                                  report,
                                                  '102_251'
                                                )}
                                              >
                                                {(
                                                  (prevReport) =>
                                                  (...args) => {
                                                    const [report, vars] =
                                                      b.getReport(
                                                        '102_251',
                                                        'children',
                                                        prevReport,
                                                        'popup',
                                                        args
                                                      );

                                                    return (
                                                      <ApplicationStatusPopup
                                                        applicationStatus={b.tryCatch(
                                                          applicationStatusFn,
                                                          props,
                                                          vars
                                                        )}
                                                        key={getKey(
                                                          report,
                                                          '102_364'
                                                        )}
                                                        data-d={getKey(
                                                          report,
                                                          '102_364'
                                                        )}
                                                      />
                                                    );
                                                  }
                                                )(report)}
                                              </Popup>,
                                              <Box
                                                tag="div"
                                                className={styles.clt_98_27}
                                                key={getKey(report, '98_27')}
                                              >
                                                <Box
                                                  tag="div"
                                                  key={getKey(report, '98_49')}
                                                >
                                                  <Text
                                                    text="Status of Your Applications"
                                                    tag="h2"
                                                    key={getKey(
                                                      report,
                                                      '98_95'
                                                    )}
                                                  />
                                                  <Box
                                                    tag="div"
                                                    className={
                                                      styles.clt_101_275
                                                    }
                                                    key={getKey(
                                                      report,
                                                      '101_275'
                                                    )}
                                                  >
                                                    <Text
                                                      text="You’ve applied to the externships below, but you’ll only be placed into one of them."
                                                      tag="p"
                                                      key={getKey(
                                                        report,
                                                        '98_96'
                                                      )}
                                                    />
                                                    <Text
                                                      text="We'll be reviewing you for all of these Externships as you progress through the application process."
                                                      tag="p"
                                                      key={getKey(
                                                        report,
                                                        '101_277'
                                                      )}
                                                    />
                                                    <Text
                                                      text="After we review your written application, we'll advance your best-fit application to the next stage."
                                                      tag="p"
                                                      key={getKey(
                                                        report,
                                                        '101_276'
                                                      )}
                                                    />
                                                  </Box>
                                                  <Box
                                                    tag="div"
                                                    className={
                                                      styles.clt_98_247
                                                    }
                                                    key={getKey(
                                                      report,
                                                      '98_247'
                                                    )}
                                                  >
                                                    {getKey(
                                                      report,
                                                      '99_833',
                                                      null,
                                                      '__CLUTCH_LOOP__'
                                                    ) &&
                                                      b
                                                        .tryCatch(
                                                          itemsFn,
                                                          props,
                                                          vars
                                                        )
                                                        ?.map(
                                                          (
                                                            item,
                                                            index,
                                                            items
                                                          ) =>
                                                            (
                                                              (prevReport) =>
                                                              (...args) => {
                                                                const [
                                                                  report,
                                                                  vars,
                                                                ] = b.getReport(
                                                                  '99_833',
                                                                  'children',
                                                                  prevReport,
                                                                  'applicationcard2',
                                                                  args
                                                                );

                                                                return (
                                                                  <ApplicationCard
                                                                    disabled={
                                                                      false
                                                                    }
                                                                    title={b.tryCatch(
                                                                      titleFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    id={b.tryCatch(
                                                                      idFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    step={b.tryCatch(
                                                                      stepFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    footer={
                                                                      getKey(
                                                                        report,
                                                                        '99_654',
                                                                        null,
                                                                        '__CLUTCH_IF__'
                                                                      ) &&
                                                                      b.tryCatch(
                                                                        conditionFn2,
                                                                        props,
                                                                        vars
                                                                      ) ? (
                                                                        <Button
                                                                          text={b.tryCatch(
                                                                            textFn,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          loading={
                                                                            false
                                                                          }
                                                                          disabled={
                                                                            false
                                                                          }
                                                                          rounded
                                                                          size="medium"
                                                                          color="info"
                                                                          onClick={b.tryCatch(
                                                                            onClickFn,
                                                                            props,
                                                                            vars
                                                                          )}
                                                                          key={getKey(
                                                                            report,
                                                                            'v200_103392'
                                                                          )}
                                                                          data-d={getKey(
                                                                            report,
                                                                            'v200_103392'
                                                                          )}
                                                                        />
                                                                      ) : null
                                                                    }
                                                                    status={b.tryCatch(
                                                                      statusFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    body={b.tryCatch(
                                                                      bodyFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    onViewProgress={
                                                                      vars
                                                                        ?.usestateappdetailspopup
                                                                        ?.setState
                                                                    }
                                                                    companyLogo={b.tryCatch(
                                                                      companyLogoFn,
                                                                      props,
                                                                      vars
                                                                    )}
                                                                    key={getKey(
                                                                      report,
                                                                      'v200_103637',
                                                                      b.tryCatch(
                                                                        keyFn,
                                                                        props,
                                                                        vars
                                                                      )
                                                                    )}
                                                                    data-d={getKey(
                                                                      report,
                                                                      'v200_103637',
                                                                      b.tryCatch(
                                                                        keyFn,
                                                                        props,
                                                                        vars
                                                                      )
                                                                    )}
                                                                  />
                                                                );
                                                              }
                                                            )(report)?.({
                                                              item,
                                                              index,
                                                              items,
                                                            })
                                                        )}
                                                  </Box>
                                                </Box>
                                                {getKey(
                                                  report,
                                                  '98_50',
                                                  null,
                                                  '__CLUTCH_IF__'
                                                ) &&
                                                b.tryCatch(
                                                  conditionFn3,
                                                  props,
                                                  vars
                                                ) ? (
                                                  <Box
                                                    tag="div"
                                                    key={getKey(
                                                      report,
                                                      'v200_103377'
                                                    )}
                                                  >
                                                    <Text
                                                      text="Not a fit at this time"
                                                      tag="h2"
                                                      key={getKey(
                                                        report,
                                                        '98_97'
                                                      )}
                                                    />
                                                    <Box
                                                      tag="div"
                                                      className={
                                                        styles.clt_101_318
                                                      }
                                                      key={getKey(
                                                        report,
                                                        '101_318'
                                                      )}
                                                    >
                                                      <Text
                                                        text="Unfortunately, we are not able to move forward with your applications below at this time."
                                                        tag="p"
                                                        key={getKey(
                                                          report,
                                                          '98_98'
                                                        )}
                                                      />
                                                      <Text
                                                        text="In the event that there is an opportunity for you to join one of the programs from below, we’ll reach out to you directly."
                                                        tag="p"
                                                        key={getKey(
                                                          report,
                                                          '101_354'
                                                        )}
                                                      />
                                                      <Text
                                                        text="We would be happy to consider your application for other programs. If you are still interested in this particular program in the future, you may re-apply to programs in this status 6 months after they are moved into this status."
                                                        tag="p"
                                                        key={getKey(
                                                          report,
                                                          '101_356'
                                                        )}
                                                      />
                                                    </Box>
                                                    <Box
                                                      tag="div"
                                                      className={
                                                        styles.clt_99_557
                                                      }
                                                      key={getKey(
                                                        report,
                                                        '99_557'
                                                      )}
                                                    >
                                                      {getKey(
                                                        report,
                                                        '101_252',
                                                        null,
                                                        '__CLUTCH_LOOP__'
                                                      ) &&
                                                        b
                                                          .tryCatch(
                                                            itemsFn1,
                                                            props,
                                                            vars
                                                          )
                                                          ?.map(
                                                            (
                                                              item,
                                                              index,
                                                              items
                                                            ) =>
                                                              (
                                                                (prevReport) =>
                                                                (...args) => {
                                                                  const [
                                                                    report,
                                                                    vars,
                                                                  ] =
                                                                    b.getReport(
                                                                      '101_252',
                                                                      'children',
                                                                      prevReport,
                                                                      'applicationcard5',
                                                                      args
                                                                    );

                                                                  return (
                                                                    <ApplicationCard
                                                                      disabled
                                                                      title={
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.job_name
                                                                      }
                                                                      id={
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.application_id
                                                                      }
                                                                      step={b.tryCatch(
                                                                        stepFn1,
                                                                        props,
                                                                        vars
                                                                      )}
                                                                      status={
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.status
                                                                      }
                                                                      companyLogo={
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.company_logo
                                                                      }
                                                                      body={b.tryCatch(
                                                                        bodyFn1,
                                                                        props,
                                                                        vars
                                                                      )}
                                                                      key={getKey(
                                                                        report,
                                                                        'v200_103650',
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.application_id
                                                                      )}
                                                                      data-d={getKey(
                                                                        report,
                                                                        'v200_103650',
                                                                        vars
                                                                          ?.applicationcard5
                                                                          ?.item
                                                                          ?.application_id
                                                                      )}
                                                                    />
                                                                  );
                                                                }
                                                              )(report)?.({
                                                                item,
                                                                index,
                                                                items,
                                                              })
                                                          )}
                                                    </Box>
                                                  </Box>
                                                ) : null}
                                              </Box>,
                                            ];
                                          }
                                        )(report)}
                                      </UseState>
                                    </Box>
                                  ) : null,
                                  getKey(
                                    report,
                                    '101_398',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) &&
                                  vars?.useapplicationsstatus2?.isLoading ? (
                                    <Loading
                                      className={styles.clt_v200_99940}
                                      key={getKey(report, 'v200_99940')}
                                      data-d={getKey(report, 'v200_99940')}
                                    />
                                  ) : null,
                                  getKey(
                                    report,
                                    '101_404',
                                    null,
                                    '__CLUTCH_IF__'
                                  ) && b.tryCatch(conditionFn4, props, vars) ? (
                                    <_404
                                      key={getKey(report, 'v200_103407')}
                                      data-d={getKey(report, 'v200_103407')}
                                    />
                                  ) : null,
                                ];
                              }
                            )(report)}
                          </UseApplicationsStatus>
                        );
                      }
                    )(report)}
                  </UseParams>
                );
              }
            )(report)}
            key={getKey(report, '98_4')}
          />
          <Route
            path="/portfolio/:loginString/:jobEnrollmentID"
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '145_60',
                  'render',
                  prevReport,
                  'routeportfolio',
                  args
                );

                return (
                  <PortfolioPage
                    key={getKey(report, '145_714')}
                    data-d={getKey(report, '145_714')}
                  />
                );
              }
            )(report)}
            exact={false}
            key={getKey(report, '145_60')}
          />
          <Route
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '96_277',
                  'render',
                  prevReport,
                  'route404',
                  args
                );

                return (
                  <_404
                    key={getKey(report, '96_279')}
                    data-d={getKey(report, '96_279')}
                  />
                );
              }
            )(report)}
            key={getKey(report, '96_277')}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
});

PublicApp.displayName = 'PublicApp';

export default PublicApp;
