/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import Icon from '../Icon';
import classNameFn from './code/icon/classNameFn.js';
import onClickFn from './code/box/onClickFn.js';
import styleFn from './code/box/styleFn.js';
import initialValueFn from './code/usestate1isopen/initialValueFn.js';
import styles from './styles.module.scss';

const Accordion = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '85_150',
    propsArg,
    vars,
    variants
  );

  return (
    <UseState
      initialValue={b.tryCatch(initialValueFn, props, vars)}
      className={props.className}
      ref={ref}
      key={getKey(report, '85_193')}
    >
      {(
        (prevReport) =>
        (...args) => {
          const [report, vars] = b.getReport(
            '85_193',
            'children',
            prevReport,
            'usestate1isopen',
            args
          );

          return (
            <Box tag="div" key={getKey(report, '85_113')}>
              <Box
                tag="div"
                onClick={b.tryCatch(onClickFn, props, vars)}
                className={styles.clt_85_1}
                key={getKey(report, '85_1')}
              >
                <Text
                  text={props?.title}
                  tag="span"
                  className={styles.clt_85_2}
                  key={getKey(report, '85_2')}
                />
                <Icon
                  className={b.cx([
                    styles.clt_85_35,
                    b.tryCatch(classNameFn, props, vars),
                  ])}
                  name="directional-down"
                  key={getKey(report, '85_35')}
                  data-d={getKey(report, '85_35')}
                />
              </Box>
              <Box
                style={{
                  display: b.tryCatch(styleFn, props, vars),
                }}
                tag="div"
                className={styles.clt_85_114}
                key={getKey(report, '85_114')}
              >
                {getKey(report, '85_174', null, '__CLUTCH_SLOT__') &&
                typeof props.children === 'function'
                  ? props.children({})
                  : props.children}
              </Box>
            </Box>
          );
        }
      )(report)}
    </UseState>
  );
});

Accordion.displayName = 'Accordion';

export default Accordion;
