import navigation from '../../../../handlers/ProfilePopup/navigation';

export default (props, vars) => (event) =>
  navigation(
    event,
    props?.['students'],
    vars?.['usestateprofilepopupopened']?.['state'],
    vars?.['usestateprofilepopupopened']?.['setState'],
    -1
  );
